import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import ProductCategory from "../pages/ProductCategory";
import Sidebar from "../components/Sidebar";
import React from 'react';
import Units from '../pages/Units';
import Customer from '../pages/Customer';
import Brand from '../pages/Brand';
import Role from '../pages/Role';
import Products from '../pages/Products';
import Warehouse from '../pages/Warehouse';
import Vendor from '../pages/Vendor';
import Expense from '../pages/Expense';
import ExpenseCategories from '../pages/ExpenseCategory';
import VendorRate from "../pages/VendorRate";
import Adjustment from "../pages/Adjustment";
import Gst from "../pages/Gst";
import Stock from "../pages/Stock";
import Purchase from "../pages/Purchase";
import Billing from '../pages/Billing';
import Sales from '../pages/Sales';
import SalesDetails from '../pages/Sales';
 
import Wastage from '../pages/Wastage';
import StocksHistory from '../pages/StocksHistory';
import BarcodeGenerator from '../pages/BarcodeGenerator';
import Report from '../pages/Report';
import Transfer from '../pages/Transfer';
import User from '../pages/User'
import StockAlert from '../pages/StockAlert';
import SearchQuery from '../pages/SearchQuery';
import Models from '../pages/Models';
import Register from '../pages/Register';
import Capital  from '../pages/Capital';
import CapitalTypes from "../pages/CapitalTypes";
import QualityChecking from '../pages/QualityChecking';
import CustomerAnalytics from '../pages/CustomerAnalytics';

const Routers = () => {
  return (
    <Router>
      <Routes>
        <Route index element={<Login />} />
        <Route element={<Sidebar />}>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/ProductCategory" element={<ProductCategory />} />
          <Route path="/Warehouse" element={<Warehouse />} />
          <Route path="/Adjustment" element={<Adjustment />} />
          <Route path="/Units" element={<Units />} />
          <Route path="/Customer" element={<Customer />} />
          <Route path="/Brand" element={<Brand />} />
          <Route path='/Role' element={<Role />} />
          <Route path='/Vendor' element={<Vendor />} />
          <Route path='/Expense' element={<Expense />} />
          <Route path='/ExpenseCategories' element={<ExpenseCategories />} />
          <Route path='/Products' element={<Products />} />
          <Route path='/Adjustment' element={<Adjustment />} />
          <Route path='/Gst' element={<Gst />} />
          <Route path='/Sales' element={<Sales />} />
          <Route path='/Sales/:invoice' element={<SalesDetails />} /> 
          <Route path='/Sales/:formattedDate' element={<SalesDetails />} /> 
          
          <Route path='/Wastage' element={<Wastage />} />
          <Route path='/StocksHistory' element={<StocksHistory />} />
          <Route path='/VendorRate' element={<VendorRate />} />
          <Route path='/Purchase' element={<Purchase />} />
          <Route path='/Stock' element={<Stock />} />
          <Route path='/BarcodeGenerator' element={<BarcodeGenerator />} />
          <Route path='User' element={<User />} />
          <Route path='/Report' element={<Report />} />
          <Route path='/Transfer' element={<Transfer />} />
          <Route path='/StockAlert' element={<StockAlert />} />
          <Route path='/Register' element={<Register/>} />
          <Route path='/SearchQuery' element={<SearchQuery />} />
          <Route path='/Models' element={<Models />} />
          <Route path="/Capital" element={<Capital/>}/>
          <Route path ="/CapitalTypes" element={<CapitalTypes/>}/>
          <Route path='/QualityChecking' element={<QualityChecking />} />
          <Route path="/Customer-Analytics/:customerId/:customerName" element={<CustomerAnalytics />} />


        </Route>
        <Route path='/Billing' element={<Billing />} />




      </Routes>
    </Router>
  );
};

export default Routers;
