import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import f2b_logo from "../Assets/Billingpage/invoice-logo-f2b.jpg";
import f2bLink from "../Assets/Billingpage/f2bLink.png";


const SalesView = ({ isOpen, onClose, itemId }) => {
    const [itemData, setItemData] = useState(null);
    const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);

    useEffect(() => {
        const fetchItemData = async () => {
            try {
                const token = localStorage.getItem('token');
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                const response = await fetch(`https://pos.farm2bag.com/api/v1/billing/purchase/${itemId}`, {
                    headers: headers
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setItemData(data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setItemData(null);
            }
        };

        if (isOpen && itemId) {
            fetchItemData();
        }
    }, [isOpen, itemId]);

    const downloadPdf = () => {
        const invoiceContentElement = document.getElementById('invoice-content');
        const downloadButtonElement = document.getElementById('download-button');
        const printButtonElement = document.getElementById('print-button');

        if (!invoiceContentElement || !downloadButtonElement || !printButtonElement) {
            console.error('Invoice content element or button elements not found');
            return;
        }

        // Disable download button to prevent multiple clicks
        setDownloadButtonDisabled(true);

        // Hide buttons before capturing content
        downloadButtonElement.style.display = 'none';
        printButtonElement.style.display = 'none';

        // Capture the entire content of the invoice-content element, including parts that overflow the viewport
        html2canvas(invoiceContentElement, {
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            windowWidth: invoiceContentElement.scrollWidth, // Capture the full width of the content
            windowHeight: invoiceContentElement.scrollHeight // Capture the full height of the content
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // Calculate dimensions
            const imgWidth = canvas.width * 0.264583; // Convert px to mm
            const imgHeight = canvas.height * 0.264583; // Convert px to mm
            let heightLeft = imgHeight;
            let position = 0;

            // Add the first page
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;

            // Add additional pages
            while (heightLeft > 0) {
                position -= pdfHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, imgHeight);
                heightLeft -= pdfHeight;
            }

            // Save PDF
            pdf.save('invoice.pdf');

            // Show buttons after PDF download
            downloadButtonElement.style.display = 'block';
            printButtonElement.style.display = 'block';
        }).catch((error) => {
            console.error('Error capturing invoice content:', error);

            // Ensure buttons are visible even if there's an error
            downloadButtonElement.style.display = 'block';
            printButtonElement.style.display = 'block';
        }).finally(() => {
            // Enable download button again after PDF processing
            setDownloadButtonDisabled(false);
        });
    };

    const printInvoice = () => {
        const invoiceContentElement = document.getElementById('invoice-content');
        const printButtonElement = document.getElementById('print-button');
        const downloadButtonElement = document.getElementById('download-button');

        if (!invoiceContentElement || !printButtonElement || !downloadButtonElement) {
            console.error('Invoice content element, print button, or download button not found');
            return;
        }

        // Disable both print and download buttons
        printButtonElement.style.display = 'none';
        downloadButtonElement.style.display = 'none';

        html2canvas(invoiceContentElement, {
            scale: 3, // Increase scale for higher quality (3x the default)
            useCORS: true, // Allow cross-origin images to be captured
            windowWidth: invoiceContentElement.scrollWidth,
            windowHeight: invoiceContentElement.scrollHeight
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const printWindow = window.open('', '', 'width=800,height=600');

            printWindow.document.open();
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print Invoice</title>
                        <style>
                            body { margin: 0; padding: 0; }
                            .invoice { width: 100%; }
                            img { width: 100%; }
                            @media print {
                                .hide-print { display: none; }
                            }
                        </style>
                    </head>
                    <body>
                        <div class="invoice">
                            <img src="${imgData}" alt="Invoice"/>
                        </div>
                        <script>
                            window.onload = function() {
                                window.print();
                                window.onafterprint = function() {
                                    window.close();
                                };
                            };
                        </script>
                    </body>
                </html>
            `);
            printWindow.document.close();

            // Ensure buttons are visible again after printing
            printWindow.onbeforeunload = () => {
                printButtonElement.style.display = 'inline-block';
                downloadButtonElement.style.display = 'inline-block';
            };
        }).catch((error) => {
            console.error('Error capturing invoice content:', error);

            // Ensure buttons are visible again in case of error
            printButtonElement.style.display = 'inline-block';
            downloadButtonElement.style.display = 'inline-block';
        });
    };


    const calculateTotal = () => {
        if (itemData && itemData.billingItems) {
            return itemData.billingItems.reduce((total, billingItem) => {
                return total + (billingItem.product ? billingItem.product.reduce((itemTotal, prod) => itemTotal + (prod.totalPrice || 0), 0) : 0);
            }, 0);
        }
        return 0;
    };

    const productsTotal = itemData ? calculateTotal() : 0;
    const totalPrice = itemData ? itemData.totalPrice || 0 : 0;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Invoice"
            className="flex items-center justify-center"
            overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
            <div id="invoice-content" className="bg-white p-2 rounded-lg shadow-lg w-96 max-h-screen overflow-y-auto">
                {itemData ? (
                    <>
                        <div className="text-center mt-20">
                            <img src={f2b_logo} alt="Company Logo" className="mx-auto h-12" />
                            <p className="text-sm text-black font-bold"> Farm2bag, 5/396 , Rajeev Gandhi Salai, OMR Thoraipakkam , Chennai, Tamil Nadu 600097</p>
                            <p className='text-sm text-black font-bold'>PH-095000 37221</p>
                        </div>
                        <h2 className="text-xl font-bold mb-4 text-center">Invoice</h2>
                        <div className="mb-4">
                            <table className="min-w-full bg-white border-none">
                                <tbody>
                                    <tr className="border-b">
                                        <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Invoice Number</th>
                                        <td className="px-4 py-1 text-black font-bold">{itemData.invoice || 'N/A'}</td>
                                    </tr>
                                    <tr className="border-b">
                                        <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Date</th>
                                        <td className="px-2 py-1 text-black font-semibold">{itemData.invoiceDate || 'N/A'}
                                            <span className='ml-2'>      {new Date(itemData.createdAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}</span>
                                        </td>
                                    </tr>
                                    <tr className="border-b">
                                        <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Customer Name</th>
                                        <td className="px-4 py-1 text-black font-bold">{itemData.customerDetails?.name || 'N/A'}</td>
                                    </tr>
                                    {/* <tr className="border-b">
                                        <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Products Value</th>
                                        <td className="px-4 py-1 text-black font-bold">
                                            ₹ {productsTotal.toFixed(2) || 'N/A'}
                                        </td>
                                    </tr> */}
                                    <tr className="border-b">
                                        <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">GST(%)</th>
                                        <td className="px-4 py-1 text-black font-bold">{itemData.gst + " %" || 'N/A'}</td>
                                    </tr>
                                    <tr className="border-b">
                                        <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Discount(%)</th>
                                        <td className="px-4 py-1 text-black font-bold">{itemData.discount + " %" || 'N/A'}</td>
                                    </tr>
                                    <tr className="border-b">
                                        <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Shipping Charge</th>
                                        <td className="px-4 py-1 text-black font-bold">₹ {itemData.shippingCharges || 'N/A'}</td>
                                    </tr>

                                    <tr className="border-b">
                                        <th className="px-4 py-1 text-left text-black bg-gray-100 text-nowrap">Payment Mode</th>
                                        <td className="px-4 py-1 text-black font-bold">{itemData.paymentMode || 'N/A'} / {itemData.status || 'N/A'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <table className="min-w-full bg-white border border-gray-200">
                            <thead className="bg-gray-100">
                                <tr>
                                    <th className=" py-1 text-left text-sm font-bold text-black uppercase">Product</th>
                                    <th className=" py-1 text-left text-sm font-bold text-black uppercase"> Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemData.billingItems && itemData.billingItems.length > 0 && itemData.billingItems.some(billingItem => billingItem.product && billingItem.product.length > 0) ? (
                                    itemData.billingItems.map((billingItem) => (
                                        billingItem.product?.map((prod, index) => (
                                            <tr key={`${billingItem.id}-${index}`} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                                                <td className="px-1 py-1 text-sm font-bold text-wrap text-black truncate overflow-hidden" style={{ maxWidth: "350px" }}>{prod.productName} <br /> ({prod.quantity}{prod.unit} x ₹{(prod.totalPrice / prod.quantity).toFixed(1)})</td>
                                                <td className=" py-1 whitespace-nowrap font-bold text-sm text-black">₹ {prod.totalPrice?.toFixed(2) || 'N/A'}</td>
                                            </tr>
                                        ))
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="px-1 py-2 text-center text-sm text-black">
                                            No products Purchased This Invoice
                                        </td>
                                    </tr>
                                )}
                                <tr className="bg-gray-100">
                                    <td colSpan="1" className=" py-1 whitespace-nowrap text-sm font-bold text-gray-900 text-right">Total : </td>
                                    <td className=" py-1 whitespace-nowrap text-sm font-bold text-black ">
                                        ₹ {productsTotal.toFixed(2) || 'N/A'}
                                    </td>
                                </tr>
                                <tr className="border-black border-2">
                                    <th className=" py-1 text-center text-black text-nowrap font-black pl-5">Grand Total : {itemData.totalPrice
                                            ? new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(itemData.totalPrice)
                                            : 'N/A'}</th>
                                </tr>
                            </tbody>
                        </table>

                        <div className='text-center'>
                            <p className='text-lg font-extrabold'>Thank You For<span> Shopping With Us.</span><br /> Please vist again.</p>
                            <img src={f2bLink} alt="Company Logo" className="h-20 w-20 block mt-2 mx-auto" />
                        </div>

                        <div className='flex justify-center mt-1'>
                            <button onClick={onClose} className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none hidden">
                                Close
                            </button>
                            <button onClick={downloadPdf} id="download-button" className={`bg-green-500 text-white h-10 p-2 rounded-lg hover:bg-green-600 focus:outline-none ${downloadButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={downloadButtonDisabled}>
                                Download PDF
                            </button>
                            <button onClick={printInvoice} id="print-button" className="bg-yellow-500 text-white h-10 p-2 rounded-lg hover:bg-yellow-600 focus:outline-none ml-2">
                                Print
                            </button>
                        </div>

                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </Modal>
    );
};

export default SalesView;