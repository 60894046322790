// import React, { useRef, useEffect } from 'react';

// const CategoryDropdown = ({ products, onSelect, highlightedIndex, onArrowKeyNavigation, dropDownClose }) => {
//   const dropdownRef = useRef(null);
//   const itemRefs = useRef([]);

//   useEffect(() => {
//     if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
//       const item = itemRefs.current[highlightedIndex];
//       const dropdown = dropdownRef.current;

//       // Calculate item position relative to the dropdown's scroll position
//       const itemTop = item.offsetTop;
//       const itemBottom = itemTop + item.clientHeight;
//       const dropdownScrollTop = dropdown.scrollTop;
//       const dropdownHeight = dropdown.clientHeight;

//       // Always scroll to the top if the first item is highlighted
//       if (highlightedIndex === 0) {
//         dropdown.scrollTop = 0;
//       }
//       // Scroll up if the highlighted item is above the visible area
//       else if (itemTop < dropdownScrollTop) {
//         dropdown.scrollTop = itemTop;
//       }
//       // Scroll down if the highlighted item is below the visible area
//       else if (itemBottom > dropdownScrollTop + dropdownHeight) {
//         dropdown.scrollTop = itemBottom - dropdownHeight;
//       }
//     }
//   }, [highlightedIndex]);

//   const handleKeyDown = (e) => {
//     if (e.key === 'ArrowDown') {
//       e.preventDefault();
//       onArrowKeyNavigation('down');
//     } else if (e.key === 'ArrowUp') {
//       e.preventDefault();
//       onArrowKeyNavigation('up');
//     } else if (e.key === 'Enter') {
//       e.preventDefault();
//       if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
//         onSelect(products[highlightedIndex]);
//       }
//     }
//   };

//   return (
//     <div
//       ref={dropdownRef}
//       className="mt-2 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-auto"
//       style={{ width: "450px" }}
//       tabIndex={0}
//       onKeyDown={handleKeyDown}>

//       <div className="sticky top-0 left-0 right-0 bg-white z-10">
//         <button
//           className="text-red-500 float-right p-1 bg-yellow-400"
//           onClick={dropDownClose}
//           aria-label="Close dropdown"
//         >
//           ❌
//         </button>
//       </div>
//       {products.length === 0 ? (
//         <div className="p-2 text-gray-600">No results</div>
//       ) : (
//         products.map((product, index) => (
//           <div
//             key={product.productsCode}
//             ref={el => (itemRefs.current[index] = el)}
//             className={`p-2 hover:bg-green-500 cursor-pointer ${index === highlightedIndex ? 'bg-green-500' : index % 2 === 0 ? 'bg-blue-100' : 'bg-yellow-100'
//               }`}
//             onClick={() => onSelect(product)}
//           >
//             <div className="text-left font-semibold mr-8">
//               {product.name}
//               <span style={{ fontSize: "15px" }} className='text-gray-600'>
//                 ({product.productsCode})
//               </span>
//             </div>
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

// export default CategoryDropdown;

import React, { useRef, useEffect, forwardRef } from 'react';

const CategoryDropdown = forwardRef(({ products, onSelect, highlightedIndex, onArrowKeyNavigation, dropDownClose }, ref) => {
  const dropdownRef = useRef(null);
  const itemRefs = useRef([]);

  useEffect(() => {
    if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
      const item = itemRefs.current[highlightedIndex];
      const dropdown = dropdownRef.current;

      // Calculate item position relative to the dropdown's scroll position
      const itemTop = item.offsetTop;
      const itemBottom = itemTop + item.clientHeight;
      const dropdownScrollTop = dropdown.scrollTop;
      const dropdownHeight = dropdown.clientHeight;

      // Always scroll to the top if the first item is highlighted
      if (highlightedIndex === 0) {
        dropdown.scrollTop = 0;
      }
      // Scroll up if the highlighted item is above the visible area
      else if (itemTop < dropdownScrollTop) {
        dropdown.scrollTop = itemTop;
      }
      // Scroll down if the highlighted item is below the visible area
      else if (itemBottom > dropdownScrollTop + dropdownHeight) {
        dropdown.scrollTop = itemBottom - dropdownHeight;
      }
    }
  }, [highlightedIndex]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      onArrowKeyNavigation('down');
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      onArrowKeyNavigation('up');
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (highlightedIndex >= 0 && itemRefs.current[highlightedIndex]) {
        onSelect(products[highlightedIndex]);
        dropDownClose(); // Optional: close the dropdown after selection
      }
    }
  };

  // Ensure the dropdown is focused when it mounts
  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, []);

  return (
    <div
      ref={dropdownRef}
      className="mt-2 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-auto"
      style={{ width: "450px" }}
      tabIndex={0}  // Allows the div to receive focus
      onKeyDown={handleKeyDown}
    >
      <div className="sticky top-0 left-0 right-0 bg-white z-10">
        <button
          className="text-red-500 float-right p-1 bg-yellow-400"
          onClick={dropDownClose}
          aria-label="Close dropdown"
        >
          ❌
        </button>
      </div>
      {products.length === 0 ? (
        <div className="p-2 text-gray-600">No results</div>
      ) : (
        products.map((product, index) => (
          <div
            key={product.productsCode}
            ref={el => (itemRefs.current[index] = el)}
            className={`p-2 hover:bg-green-500 cursor-pointer ${index === highlightedIndex ? 'bg-green-500' : index % 2 === 0 ? 'bg-blue-100' : 'bg-yellow-100'}`}
            onClick={() => {
              onSelect(product);
              dropDownClose(); // Optional: close dropdown on selection
            }}
          >
            <div className="text-left font-semibold mr-8">
              {product.name}
              <span style={{ fontSize: "15px" }} className='text-gray-600'>
                ({product.productsCode})
              </span>
            </div>
          </div>
        ))
      )}
    </div>
  );
});

export default CategoryDropdown;
