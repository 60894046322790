import { Outlet, Link, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import logo from "../Assets/SideIcon/F2Blogo.svg";
import notification from "../Assets/SideIcon/notification.svg";
import message from "../Assets/SideIcon/message.svg";
import downArrow from "../Assets/SideIcon/DownArrow.png";
import "../scss/Sidebar.scss";
import { FaSignOutAlt } from "react-icons/fa";

const Sidebar = () => {
  const posButtonRef = useRef(null); // Reference for the POS button
  const [activeNavItem, setActiveNavItem] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpentwo, setDropdownOpentwo] = useState(false);
  const[dropdownopenthree,setDropdownOpenthree] = useState(false);
  const [modelsName, setModelsName] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedModelsName = localStorage.getItem("modelNames");
    console.log("storedModelsName", storedModelsName);
    if (storedModelsName) {
      // Convert comma-separated string to array
      setModelsName(storedModelsName.split(','));
    } else {
      setModelsName([]);
    }
  }, []);

    // useEffect hook to add a keydown event listener for the shortcut
    useEffect(() => {
      const handleKeyPress = (event) => {
          if (event.ctrlKey && event.key === 'b') { // Ctrl + B as the shortcut key
              if (posButtonRef.current) {
                  posButtonRef.current.click(); // Programmatically trigger the button click
              }
          }
      };

      window.addEventListener('keydown', handleKeyPress);

      // Clean up the event listener on component unmount
      return () => {
          window.removeEventListener('keydown', handleKeyPress);
      };
  }, []);
  // login out code
  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        window.location.replace("/"); // Redirect to the login page
      }
    };

    // Initial check
    checkToken();

    // Set an interval to check every minute
    const intervalId = setInterval(checkToken, 60000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const userName = localStorage.getItem("userName");
 // Function to handle the button click
 const handlePosClick = () => {
  // Navigate to the billing page or trigger your logic
  navigate('/billing'); // Change this path based on your routes
};
  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdowntwo = () => {
    setDropdownOpentwo(!dropdownOpentwo);
  };

  const toggleDropdownThree = () => {
    setDropdownOpenthree(!dropdownopenthree);
  };

  // Helper function to check if the sidebar item should be displayed
  const shouldDisplay = (itemName) => {
    return modelsName.length === 0 || modelsName.includes(itemName);
  };


  useEffect(() => {
    // Retrieve the stored active menu item from localStorage
    const storedActiveItem = localStorage.getItem("activeNavItem");
    if (storedActiveItem) {
      setActiveNavItem(storedActiveItem);
    }
  }, []);

  const handleNavItemClick = (navItem) => {
    // Store the active menu item in localStorage
    localStorage.setItem("activeNavItem", navItem);
    setActiveNavItem(navItem);
  };

  return (
    <div className="flex h-screen">
      <nav
        style={{ background: "rgba(8, 22, 74)" }}
        className=" w-64 flex flex-col fixed top-0 left-0 h-full w-64  py-4 px-8 z-40 custom-scrollbar"
      >
        <div className="flex items-center justify-center h-20">
          <img src={logo} className="h-15" alt="logo" />
        </div>
        <div className=" ml-3.5 flex-1 overflow-y-auto">
          <ul className="mt-6 leading-10">
            <h6 style={{ color: "white" }} className="mt-1 text-xs text-left">
              MENU
            </h6>

            {shouldDisplay("Dashboard") && (
              <li
                className={`flex items-center ${activeNavItem === "Dashboard"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Dashboard"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Dashboard")}
                  className="flex items-center py-1 h-10 pr-12 ml-4"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M8.09886 3.37085L3.49599 6.9575C2.72743 7.55527 2.10403 8.82767 2.10403 9.79265V16.1205C2.10403 18.1017 3.71802 19.7242 5.69922 19.7242H15.5881C17.5693 19.7242 19.1833 18.1017 19.1833 16.1291V9.91221C19.1833 8.87891 18.4916 7.55527 17.6462 6.96604L12.3687 3.26838C11.1731 2.43149 9.25171 2.47419 8.09886 3.37085Z"
                      stroke="white"
                      strokeWidth="1.53713"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.6436 16.3084V13.7465"
                      stroke="white"
                      strokeWidth="1.53713"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="truncate">Dashboard</span>
                </Link>
              </li>
            )}

            {shouldDisplay("Products") && (
              <li className="items-center">
                <div
                  onClick={toggleDropdown}
                  style={{ color: "white" }}
                  className={
                    "flex items-center rounded-md ms-3 mb-1 hover:bg-lime-500 hover:rounded-md cursor-pointer"
                  }
                >
                  <svg
                    width="30"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-1"
                  >
                    <path
                      d="M19.1833 8.2018V4.3248C19.1833 3.12072 18.6367 2.63396 17.2789 2.63396H13.8289C12.4711 2.63396 11.9246 3.12072 11.9246 4.3248V8.19326C11.9246 9.40588 12.4711 9.8841 13.8289 9.8841H17.2789C18.6367 9.89264 19.1833 9.40588 19.1833 8.2018Z"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.1833 17.8089V14.3589C19.1833 13.0011 18.6367 12.4545 17.2789 12.4545H13.8289C12.4711 12.4545 11.9246 13.0011 11.9246 14.3589V17.8089C11.9246 19.1667 12.4711 19.7132 13.8289 19.7132H17.2789C18.6367 19.7132 19.1833 19.1667 19.1833 17.8089Z"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.36272 8.2018V4.3248C9.36272 3.12072 8.81618 2.63396 7.45838 2.63396H4.00837C2.65057 2.63396 2.10403 3.12072 2.10403 4.3248V8.19326C2.10403 9.40588 2.65057 9.8841 4.00837 9.8841H7.45838C8.81618 9.89264 9.36272 9.40588 9.36272 8.2018Z"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.36272 17.8089V14.3589C9.36272 13.0011 8.81618 12.4545 7.45838 12.4545H4.00837C2.65057 12.4545 2.10403 13.0011 2.10403 14.3589V17.8089C2.10403 19.1667 2.65057 19.7132 4.00837 19.7132H7.45838C8.81618 19.7132 9.36272 19.1667 9.36272 17.8089Z"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="truncate">Products</span>
                  <img src={downArrow} className="ml-2 mt-1 w-3 h-4" alt="dropdown arrow" />
                </div>

                {dropdownOpen && (
                  <div className="dropdown-content">
                    <ul className="leading-10 pl-14">
                      {/* Conditionally render Product link */}
                      {shouldDisplay("Products") && (
                        <li
                          className={`flex items-center ${activeNavItem === "Products"
                            ? "bg-lime-500 rounded-md mb-1"
                            : "hover:bg-lime-500 hover:rounded-md mb-1"
                            }`}
                        >
                          <Link
                            to="/Products"
                            style={{ color: "white" }}
                            onClick={() => handleNavItemClick("Products")}
                            className="flex items-center py-1 pr-10 px-4 h-10"
                          >
                            <span className="truncate">Products</span>
                          </Link>
                        </li>
                      )}

                      {/* Conditionally render Categories link */}
                      {shouldDisplay("categories") && (
                        <li
                          className={`flex items-center ${activeNavItem === "categories"
                            ? "bg-lime-500 rounded-md mb-1"
                            : "hover:bg-lime-500 hover:rounded-md mb-1"
                            }`}
                        >
                          <Link
                            to="/ProductCategory"
                            style={{ color: "white" }}
                            onClick={() => handleNavItemClick("categories")}
                            className="flex items-center py-1 px-4 pr-6 h-10"
                          >
                            <span className="truncate">Categories</span>
                          </Link>
                        </li>
                      )}

                      {/* Conditionally render Units link */}
                      {shouldDisplay("units") && (
                        <li
                          className={`flex items-center ${activeNavItem === "units"
                            ? "bg-lime-500 rounded-md mb-1"
                            : "hover:bg-lime-500 hover:rounded-md mb-1"
                            }`}
                        >
                          <Link
                            to="/Units"
                            style={{ color: "white" }}
                            onClick={() => handleNavItemClick("units")}
                            className="flex items-center py-1 px-4 pr-16 h-10"
                          >
                            <span className="truncate">Units</span>
                          </Link>
                        </li>
                      )}

                      {/* Conditionally render Brand link */}
                      {shouldDisplay("brand") && (
                        <li
                          className={`flex items-center ${activeNavItem === "brand"
                            ? "bg-lime-500 rounded-md mb-1"
                            : "hover:bg-lime-500 hover:rounded-md mb-1"
                            }`}
                        >
                          <Link
                            to="/Brand"
                            style={{ color: "white" }}
                            onClick={() => handleNavItemClick("brand")}
                            className="flex items-center py-1 px-4 h-10 pr-14"
                          >
                            <span className="truncate">Brand</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </li>
            )}

            {shouldDisplay("Billing") && (
              <li
                className={`flex items-center ${activeNavItem === "Billing"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Billing"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Billing")}
                  className="flex items-center py-1 px-4 h-10 pr-20"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M19.1833 6.03024V8.09683C19.1833 9.44609 18.3293 10.3001 16.98 10.3001H14.0595V4.33086C14.0595 3.38296 14.8366 2.61439 15.7845 2.61439C16.7153 2.62293 17.5693 2.99867 18.1841 3.61353C18.799 4.23692 19.1833 5.09088 19.1833 6.03024Z"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.10403 6.88421V18.8397C2.10403 19.5485 2.90674 19.9498 3.47035 19.5229L4.93065 18.4298C5.27223 18.1736 5.75046 18.2078 6.05789 18.5152L7.47544 19.9413C7.80849 20.2743 8.35506 20.2743 8.68811 19.9413L10.1228 18.5066C10.4216 18.2078 10.8999 18.1736 11.2329 18.4298L12.6932 19.5229C13.2568 19.9413 14.0595 19.5399 14.0595 18.8397V4.32232C14.0595 3.38296 14.8281 2.61439 15.7674 2.61439H6.37385H5.51989C2.958 2.61439 2.10403 4.14298 2.10403 6.03024V6.88421Z"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.51987 8.59213H10.6436"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.16034 12.008H10.0032"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="truncate">Billing</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Sales") && (
              <li
                className={`flex items-center ${activeNavItem === "Sales"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Sales"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Sales")}
                  className="flex items-center py-1 px-4 h-10 pr-24"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M3 3H5L6 14H19L20 8H7"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="9"
                      cy="20"
                      r="1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="17"
                      cy="20"
                      r="1"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="truncate">Sales</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Inventory") && (
              <li
                className={`flex items-center ${activeNavItem === "Inventory"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Stock"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Inventory")}
                  className="flex items-center py-1 px-4 h-10 pr-16"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M17.4103 16.7257V8.98313C17.4103 8.77779 17.3287 8.58085 17.1835 8.43565C17.0383 8.29045 16.8414 8.20888 16.636 8.20888H13.539"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.47348 16.7256V4.33755C3.47348 4.1322 3.55505 3.93527 3.70025 3.79007C3.84545 3.64487 4.04239 3.56329 4.24773 3.56329H12.7645C12.9699 3.56329 13.1668 3.64487 13.312 3.79007C13.4572 3.93527 13.5388 4.1322 13.5388 4.33755V16.7256"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.37671 6.83548H10.6351"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.37671 10.1444H10.6351"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.37695 16.7256H10.6353V13.3923H6.37695V16.7256Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.1846 16.7256H2.69955"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="truncate">Inventory</span>
                </Link>
              </li>

            )}

            {shouldDisplay("BarCodeX") && (
              <li
                className={`flex items-center ${activeNavItem === "BarcodeGenerator"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1 "
                  }`}
              >
                <Link
                  to="/BarcodeGenerator"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("BarcodeGenerator")}
                  className="flex items-center py-1 px-4 h-10 pr-10"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <rect x="2" y="2" width="2" height="20" fill="white" />
                    <rect x="5" y="2" width="1" height="20" fill="white" />
                    <rect x="7" y="2" width="3" height="20" fill="white" />
                    <rect x="11" y="2" width="1" height="20" fill="white" />
                    <rect x="13" y="2" width="2" height="20" fill="white" />
                    <rect x="16" y="2" width="1" height="20" fill="white" />
                    <rect x="18" y="2" width="3" height="20" fill="white" />
                  </svg>

                  <span className="truncate">BarCodeX</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Warehouse") && (
              <li
                className={`flex items-center ${activeNavItem === "Warehouse"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Warehouse"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Warehouse")}
                  className="flex items-center py-1 px-4 h-10 pr-14"
                >
                  <svg
                    fill="white"
                    width="21"
                    height="22"
                    viewBox="0 0 502.56 502.56"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <g>
                        <g>
                          <path d="M467.28,467.496h24v-128h-24V129.328l12.088,3.888l23.192-46.392L251.28,3.064L0,86.824l23.192,46.392l12.088-3.888 v354.168h-32v16h32h432h32v-16h-32V467.496z M475.28,355.496v96h-8v-96H475.28z M215.6,483.496H107.28v-216h80v184h-64v16h80v-16 v-184v-16v-16h16v180.32c-9.792,8.792-16,21.504-16,35.68C203.28,463.8,207.968,474.992,215.6,483.496z M251.28,483.496 c-17.648,0-32-14.352-32-32s14.352-32,32-32s32,14.352,32,32S268.928,483.496,251.28,483.496z M296.936,309.84l-11.312,11.312 l10.344,10.344l-31.04,31.04l32.464-119.04h92.256l29.6,82.896l-97.728,45.104h-42.928l28.688-28.688l10.344,10.344 l11.312-11.312L296.936,309.84z M236.528,405.84c-0.424,0.136-0.832,0.304-1.248,0.456v-138.8h38.984L236.528,405.84z M286.96,483.496c-4.624,4.144-10.032,7.4-16,9.52v77.6c5.968,2.12,11.376,5.376,16,9.52V483.496z M419.28,483.496 c-17.648,0-32-14.352-32-32s14.352-32,32-32s32,14.352,32,32S436.928,483.496,419.28,483.496z M451.28,415.816 c-8.504-7.632-19.696-12.32-32-12.32c-26.472,0-48,21.528-48,48h-72c0-25.648-20.232-46.592-45.56-47.88l4.4-16.12h66.92 l99.608-45.976l4.992,13.976h21.64V415.816z M451.28,339.496h-10.36l-34.288-96h12.648v-16h-152v16h13.528l-2.184,8H235.28v-16 h16v-16h-80v16h16v16h-96v232h-40v-288h400V339.496z M451.28,179.496h-400v-55.312l200-64.28l200,64.288V179.496z M251.28,43.088 L31.368,113.776L22.56,96.168l228.72-76.24L480,96.168l-8.808,17.608L251.28,43.088z"></path>
                          <rect
                            x="243.28"
                            y="91.496"
                            width="16"
                            height="16"
                          ></rect>
                          <rect
                            x="275.28"
                            y="91.496"
                            width="16"
                            height="16"
                          ></rect>
                          <rect
                            x="211.28"
                            y="91.496"
                            width="16"
                            height="16"
                          ></rect>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="truncate">Warehouse</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Adjustment") && (
              <li
                className={`flex items-center ${activeNavItem === "Adjustment"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Adjustment"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Adjustment")}
                  className="flex items-center py-1 px-4 h-10 pr-12"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M3 10H21"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 14H21"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 6V18"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 6V18"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="truncate">Adjustment</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Report") && (
              <li
                className={`flex items-center ${activeNavItem === "Report"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Report"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Report")}
                  className="flex items-center py-1 px-4 pr-20 h-10  "
                >
                  <svg
                    fill="white"
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    className="mr-2"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M4 2h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zM5 4v16h14V4H5zm4 12h6v2H9v-2zm-1-4h8v2H8v-2zm0-4h8v2H8V8z"
                        fill="white"
                        stroke="none"
                      ></path>
                    </g>
                  </svg>
                  <span className="truncate">Report</span>
                </Link>
              </li>

            )}


{shouldDisplay("Register") && (
              <li
                className={`flex items-center ${activeNavItem === "Register"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Register"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Register")}
                  className="flex items-center py-1 px-4 pr-20 h-10  "
                >
                  <svg
                    fill="white"
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    className="mr-2"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M4 2h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zM5 4v16h14V4H5zm4 12h6v2H9v-2zm-1-4h8v2H8v-2zm0-4h8v2H8V8z"
                        fill="white"
                        stroke="none"
                      ></path>
                    </g>
                  </svg>
                  <span className="truncate">Ledger</span>
                </Link>
              </li>

            )}

{shouldDisplay("QualityChecking") && (
              <li
                className={`flex items-center ${activeNavItem === "QualityChecking"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/QualityChecking"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("QualityChecking")}
                  className="flex items-center py-1 px-4 pr-20 h-10  "
                >
                  <svg
                    fill="white"
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    className="mr-2"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M4 2h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zM5 4v16h14V4H5zm4 12h6v2H9v-2zm-1-4h8v2H8v-2zm0-4h8v2H8V8z"
                        fill="white"
                        stroke="none"
                      ></path>
                    </g>
                  </svg>
                  <span className="truncate">QualityChecking</span>
                </Link>
              </li>

            )}


            {shouldDisplay("Gst") && (
              <li
                className={`flex items-center ${activeNavItem === "Gst"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Gst"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Gst")}
                  className="flex items-center py-1 px-4 pr-28 h-10"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M2 12L12 2L22 12"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 12H22"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 12V22H8V12"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="truncate">Gst</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Purchase") && (
              <li
                className={`flex items-center ${activeNavItem === "Purchase"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Purchase"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Purchase")}
                  className="flex items-center py-1 px-4 h-10 pr-16"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M7.65475 13.0852C7.65475 14.7248 9.00402 16.0741 10.6436 16.0741C12.2832 16.0741 13.6325 14.7248 13.6325 13.0852"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.9195 2.62418L4.82816 5.72407"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3678 2.62418L16.4591 5.72407"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.10403 7.61986C2.10403 6.04002 2.94946 5.91193 3.99983 5.91193H17.2875C18.3379 5.91193 19.1833 6.04002 19.1833 7.61986C19.1833 9.45588 18.3379 9.32778 17.2875 9.32778H3.99983C2.94946 9.32778 2.10403 9.45588 2.10403 7.61986Z"
                      stroke="white"
                      strokeWidth="1.02476"
                    />
                    <path
                      d="M3.38498 9.45587L4.58907 16.8341C4.86233 18.4908 5.51989 19.7034 7.96222 19.7034H13.1116C15.7674 19.7034 16.1603 18.542 16.4677 16.9366L17.9023 9.45587"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="truncate">Purchase</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Wastage") && (
              <li
                className={`flex items-center ${activeNavItem === "Wastage"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Wastage"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Wastage")}
                  className="flex items-center py-1 px-4 h-10 pr-24"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M3 6H21"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 6L18 20H6L5 6"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 11V16"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 11V16"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 3H15"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="truncate">Wastage</span>
                </Link>
              </li>
            )}

            {shouldDisplay("Stocks History") && (
              <li
                className={`flex items-center ${activeNavItem === "StocksHistory"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/StocksHistory"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("StocksHistory")}
                  className="flex items-center py-1 px-4 h-10 pr-8"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M3 17L9 11L13 15L21 7"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13 7H21V15"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 21H21"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="truncate">Stocks History</span>
                </Link>
              </li>
            )}

            {shouldDisplay("Expense") && (
              <li className="items-center">
                <div
                  onClick={toggleDropdowntwo}
                  style={{ color: "white" }}
                  className="flex items-center rounded-md ms-4 mb-1 hover:bg-lime-500 cursor-pointer"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M12 3V21"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 10H18"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 14H18"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 1C10.3431 1 9 2.34315 9 4H15C15 2.34315 13.6569 1 12 1Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 23C10.3431 23 9 21.6569 9 20H15C15 21.6569 13.6569 23 12 23Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="truncate">Expense</span>
                  <div className="hover:bg-lime-600 rounded-md">
                    <img src={downArrow} className="ml-2 mt-1 w-3 h-4" />
                  </div>
                </div>

                {dropdownOpentwo && (
                  <div className="dropdown-content">
                    <ul className="leading-10 pl-14">
                      {shouldDisplay("Expense") && (
                        <li
                          className={`flex items-center ${activeNavItem === "Expense"
                            ? "bg-lime-500 rounded-md mb-1"
                            : "hover:bg-lime-500 hover:rounded-md mb-1"
                            }`}
                        >
                          <Link
                            to="/Expense"
                            style={{ color: "white" }}
                            onClick={() => handleNavItemClick("Expense")}
                            className="flex items-center py-1 px-4 h-10 pr-12"
                          >
                            <span className="truncate">Expense</span>
                          </Link>
                        </li>
                      )}
                      {shouldDisplay("ExpenseCategories") && (
                        <li
                          className={`flex items-center ${activeNavItem === "ExpenseCategories"
                            ? "bg-lime-500 rounded-md mb-1"
                            : "hover:bg-lime-500 hover:rounded-md mb-1"
                            }`}
                        >
                          <Link
                            to="/ExpenseCategories"
                            style={{ color: "white" }}
                            onClick={() => handleNavItemClick("ExpenseCategories")}
                            className="flex items-center py-1 px-4 h-10 pr-6"
                          >
                            <span className="truncate">ExpenseCategories</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </li>


            )}
{shouldDisplay("Capital") && (
              <li className="items-center">
                <div
                  onClick={toggleDropdownThree}
                  style={{ color: "white" }}
                  className="flex items-center rounded-md ms-4 mb-1 hover:bg-lime-500 cursor-pointer"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M12 3V21"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 10H18"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 14H18"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 1C10.3431 1 9 2.34315 9 4H15C15 2.34315 13.6569 1 12 1Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 23C10.3431 23 9 21.6569 9 20H15C15 21.6569 13.6569 23 12 23Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="truncate">Capital</span>
                  <div className="hover:bg-lime-600 rounded-md">
                    <img src={downArrow} className="ml-2 mt-1 w-3 h-4" />
                  </div>
                </div>

                {dropdownopenthree && (
                  <div className="dropdown-content">
                    <ul className="leading-10 pl-14">
                      {shouldDisplay("Capital") && (
                        <li
                          className={`flex items-center ${activeNavItem === "Capital"
                            ? "bg-lime-500 rounded-md mb-1"
                            : "hover:bg-lime-500 hover:rounded-md mb-1"
                            }`}
                        >
                          <Link
                            to="/Capital"
                            style={{ color: "white" }}
                            onClick={() => handleNavItemClick("Capital")}
                            className="flex items-center py-1 px-4 h-10 pr-12"
                          >
                            <span className="truncate">Capital</span>
                          </Link>
                        </li>
                      )}
                      {shouldDisplay("CapitalTypes") && (
                        <li
                          className={`flex items-center ${activeNavItem === "CapitalTypes"
                            ? "bg-lime-500 rounded-md mb-1"
                            : "hover:bg-lime-500 hover:rounded-md mb-1"
                            }`}
                        >
                          <Link
                            to="/CapitalTypes"
                            style={{ color: "white" }}
                            onClick={() => handleNavItemClick("CapitalTypes")}
                            className="flex items-center py-1 px-4 h-10 pr-6"
                          >
                            <span className="truncate">CapitalTypes</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </li>


            )}
            {shouldDisplay("Inventory Alert") && (
              <li
                className={`flex items-center ${activeNavItem === "Inventory Alert"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/StockAlert"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Inventory Alert")}
                  className="flex items-center py-1 px-4 h-10 pr-16"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M17.4103 16.7257V8.98313C17.4103 8.77779 17.3287 8.58085 17.1835 8.43565C17.0383 8.29045 16.8414 8.20888 16.636 8.20888H13.539"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.47348 16.7256V4.33755C3.47348 4.1322 3.55505 3.93527 3.70025 3.79007C3.84545 3.64487 4.04239 3.56329 4.24773 3.56329H12.7645C12.9699 3.56329 13.1668 3.64487 13.312 3.79007C13.4572 3.93527 13.5388 4.1322 13.5388 4.33755V16.7256"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.37671 6.83548H10.6351"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.37671 10.1444H10.6351"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.37695 16.7256H10.6353V13.3923H6.37695V16.7256Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.1846 16.7256H2.69955"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="truncate">Inventory Alert</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Vendor") && (
              <li
                className={`flex items-center ${activeNavItem === "Vendor"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Vendor"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Vendor")}
                  className="flex items-center py-1 px-4 h-10 pr-20"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 1024 1024"
                    className="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="white"
                    stroke="white"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M998.936064 240.238415L521.756297 4.775434a19.34316 19.34316 0 0 0-25.451526 0L19.125004 240.238415c-12.798482 0-19.125004 12.725763-19.125004 25.451527s6.326522 25.451527 19.125004 25.451526l477.179767 235.390263c6.399241 0 6.399241 6.399241 12.725763 6.399241s6.326522 0 12.725763-6.399241l375.37366-184.487209v267.24103c0 19.052286 12.798482 31.778049 31.850768 31.778049 19.125004 0 31.778049-12.725763 31.778049-31.778049V310.193754l38.17729-19.052286c12.798482 0 19.125004-12.725763 19.125004-25.451526s-6.326522-25.451527-19.125004-25.451527zM509.030534 462.902914L101.806107 265.689942 509.030534 68.40425l407.224427 197.285692L509.030534 462.902914z m279.966794-28.869303c19.052286 0 31.850768 12.725763 31.850767 31.778049v293.274306c0 6.326522-6.399241 19.052286-19.125004 25.451527A706.970693 706.970693 0 0 1 509.030534 848.16631c-108.132629 0-210.011455-19.125004-292.692557-63.628817-12.725763 0-19.125004-12.725763-19.125004-25.451527V465.81166c0-19.052286 12.798482-31.778049 31.850767-31.778049 19.125004 0 31.778049 12.725763 31.778049 31.778049v274.149302c140.056116 63.628817 356.321374 63.628817 496.37749 0v-274.149302c0-19.052286 12.653045-31.778049 31.778049-31.778049z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                  <span className="truncate">Vendor</span>
                </Link>
              </li>

            )}

            {/* {shouldDisplay("VendorRate") && (
              <li
                className={`flex items-center ${activeNavItem === "VendorRate"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/VendorRate"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("VendorRate")}
                  className="flex items-center py-1 px-4 h-10 pr-20"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M19.1833 6.03024V8.09683C19.1833 9.44609 18.3293 10.3001 16.98 10.3001H14.0595V4.33086C14.0595 3.38296 14.8366 2.61439 15.7845 2.61439C16.7153 2.62293 17.5693 2.99867 18.1841 3.61353C18.799 4.23692 19.1833 5.09088 19.1833 6.03024Z"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.10403 6.88421V18.8397C2.10403 19.5485 2.90674 19.9498 3.47035 19.5229L4.93065 18.4298C5.27223 18.1736 5.75046 18.2078 6.05789 18.5152L7.47544 19.9413C7.80849 20.2743 8.35506 20.2743 8.68811 19.9413L10.1228 18.5066C10.4216 18.2078 10.8999 18.1736 11.2329 18.4298L12.6932 19.5229C13.2568 19.9413 14.0595 19.5399 14.0595 18.8397V4.32232C14.0595 3.38296 14.8281 2.61439 15.7674 2.61439H6.37385H5.51989C2.958 2.61439 2.10403 4.14298 2.10403 6.03024V6.88421Z"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.51987 8.59213H10.6436"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.16034 12.008H10.0032"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="truncate">VendorRate</span>
                </Link>
              </li>

            )} */}

            {shouldDisplay("Customers") && (
              <li
                className={`flex items-center ${activeNavItem === "Customer"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Customer"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Customer")}
                  className="flex items-center py-1 px-4 h-10 pr-24"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M12 12C15.3137 12 18 9.31371 18 6C18 2.68629 15.3137 0 12 0C8.68629 0 6 2.68629 6 6C6 9.31371 8.68629 12 12 12Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 22C4 16.4772 7.47715 12 12 12C16.5228 12 20 16.4772 20 22"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="12"
                      cy="6"
                      r="4"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4 22C4 16.4772 7.47715 12 12 12C16.5228 12 20 16.4772 20 22"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="truncate ">Customers</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Transfers") && (
              <li
                className={`flex items-center ${activeNavItem === "Transfer"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Transfer"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Transfer")}
                  className="flex items-center py-1 px-4 h-10 pr-24"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M4 7L12 15L20 7"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 15V3"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20 17L12 9L4 17"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 9V21"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="truncate">Transfers</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Models") && (
              <li
                className={`flex items-center ${activeNavItem === "Models"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Models"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Models")}
                  className="flex items-center py-1 px-4 h-10 pr-16"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M7.65475 13.0852C7.65475 14.7248 9.00402 16.0741 10.6436 16.0741C12.2832 16.0741 13.6325 14.7248 13.6325 13.0852"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.9195 2.62418L4.82816 5.72407"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3678 2.62418L16.4591 5.72407"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.10403 7.61986C2.10403 6.04002 2.94946 5.91193 3.99983 5.91193H17.2875C18.3379 5.91193 19.1833 6.04002 19.1833 7.61986C19.1833 9.45588 18.3379 9.32778 17.2875 9.32778H3.99983C2.94946 9.32778 2.10403 9.45588 2.10403 7.61986Z"
                      stroke="white"
                      strokeWidth="1.02476"
                    />
                    <path
                      d="M3.38498 9.45587L4.58907 16.8341C4.86233 18.4908 5.51989 19.7034 7.96222 19.7034H13.1116C15.7674 19.7034 16.1603 18.542 16.4677 16.9366L17.9023 9.45587"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="truncate">Models</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Role") && (
              <li
                className={`flex items-center ${activeNavItem === "Role"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/Role"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("Role")}
                  className="flex items-center py-1 px-4 h-10 pr-24"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 1024 1024"
                    class="icon"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    stroke="white"
                    transform="matrix(1, 0, 0, 1, 0, 0)"
                    className="mr-2"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M998.936064 240.238415L521.756297 4.775434a19.34316 19.34316 0 0 0-25.451526 0L19.125004 240.238415c-12.798482 0-19.125004 12.725763-19.125004 25.451527s6.326522 25.451527 19.125004 25.451526l477.179767 235.390263c6.399241 0 6.399241 6.399241 12.725763 6.399241s6.326522 0 12.725763-6.399241l375.37366-184.487209v267.24103c0 19.052286 12.798482 31.778049 31.850768 31.778049 19.125004 0 31.778049-12.725763 31.778049-31.778049V310.193754l38.17729-19.052286c12.798482 0 19.125004-12.725763 19.125004-25.451526s-6.326522-25.451527-19.125004-25.451527zM509.030534 462.902914L101.806107 265.689942 509.030534 68.40425l407.224427 197.285692L509.030534 462.902914z m279.966794-28.869303c19.052286 0 31.850768 12.725763 31.850767 31.778049v293.274306c0 6.326522-6.399241 19.052286-19.125004 25.451527A706.970693 706.970693 0 0 1 509.030534 848.16631c-108.132629 0-210.011455-19.125004-292.692557-63.628817-12.725763 0-19.125004-12.725763-19.125004-25.451527V465.81166c0-19.052286 12.798482-31.778049 31.850767-31.778049 19.125004 0 31.778049 12.725763 31.778049 31.778049v274.149302c140.056116 63.628817 356.321374 63.628817 496.37749 0v-274.149302c0-19.052286 12.653045-31.778049 31.778049-31.778049z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                  <span className="truncate">Role</span>
                </Link>
              </li>

            )}

            {shouldDisplay("Users") && (
              <li
                className={`flex items-center ${activeNavItem === "User"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/User"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("User")}
                  className="flex items-center py-1 px-4 h-10 pr-24"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M6 13C6 10.7909 7.79086 9 10 9H14C16.2091 9 18 10.7909 18 13"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 13C6 15.2091 7.79086 17 10 17H14C16.2091 17 18 15.2091 18 13"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="9"
                      cy="6"
                      r="3"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <circle
                      cx="15"
                      cy="6"
                      r="3"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="truncate">Users</span>
                </Link>
              </li>

            )}

            {shouldDisplay("SearchQuery") && (
              <li
                className={`flex items-center ${activeNavItem === "SearchQuery"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1"
                  }`}
              >
                <Link
                  to="/SearchQuery"
                  style={{ color: "white" }}
                  onClick={() => handleNavItemClick("SearchQuery")}
                  className="flex items-center py-1 px-4 h-10 pr-16"
                >
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M7.65475 13.0852C7.65475 14.7248 9.00402 16.0741 10.6436 16.0741C12.2832 16.0741 13.6325 14.7248 13.6325 13.0852"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.9195 2.62418L4.82816 5.72407"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3678 2.62418L16.4591 5.72407"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2.10403 7.61986C2.10403 6.04002 2.94946 5.91193 3.99983 5.91193H17.2875C18.3379 5.91193 19.1833 6.04002 19.1833 7.61986C19.1833 9.45588 18.3379 9.32778 17.2875 9.32778H3.99983C2.94946 9.32778 2.10403 9.45588 2.10403 7.61986Z"
                      stroke="white"
                      strokeWidth="1.02476"
                    />
                    <path
                      d="M3.38498 9.45587L4.58907 16.8341C4.86233 18.4908 5.51989 19.7034 7.96222 19.7034H13.1116C15.7674 19.7034 16.1603 18.542 16.4677 16.9366L17.9023 9.45587"
                      stroke="white"
                      strokeWidth="1.02476"
                      strokeLinecap="round"
                    />
                  </svg>
                  <span className="truncate">SearchQuery</span>
                </Link>
              </li>
            )}


          </ul>

          <div className="mt-auto leading-10">
            <h6
              style={{ color: "white" }}
              activeClassName="text-green-300"
              className="mt-7  text-xs text-left"
            >
              OTHERS
            </h6>

            <ul>
              <li
                className={`flex items-center  ${activeNavItem === "Logout"
                  ? "bg-lime-500 rounded-md mb-1"
                  : "hover:bg-lime-500 hover:rounded-md mb-1 "
                  }`}
              >
                <div
                  style={{ color: "white" }}
                  onClick={() => {
                    localStorage.removeItem("token"); // Clear token from localStorage
                    localStorage.removeItem("role"); // Clear role from localStorage
                    localStorage.removeItem("userName");
                    window.location.replace("/");
                  }}
                  className="flex items-center py-1 px-4 h-10 cursor-pointer pr-28"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="24"
                    height="24"
                    className="mr-2"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M13 5h-2v14h2V5z" />
                    <path d="M8.5 9.5l2.5 2.5-2.5 2.5V15H5V9h3.5z" />
                  </svg>
                  <span className="truncate">Logout</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="size-full">
        <header
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
          className="flex px-4 py-1  shadow-md fixed top-0 left-0 w-full bg-gray-800  py-4 z-50"
        >
          <img src={logo} className="h-14 w-14" alt="logo" />
          <h1
            style={{ color: "black" }}
            className="w-38 h-6 mt-2 ml-1 font-bold text-4xl leading-10"
          >
            farm2bag
          </h1>
          <div className="flex gap-4 ml-auto ">
            <div className="flex gap-4">
              <img
                src={notification}
                alt="notification"
                className="w-10 h-10 mr-4 mt-2 max-w-md mx-auto "
              />

              <img
                src={message}
                alt="message"
                className="w-10 h-10 mt-2 max-w-md mx-auto mr-14 "
              />
              <li
                className={`flex items-center  ${activeNavItem === "Billing"}`}
              >
                {/* <Link
                  to="/Billing"
                  style={{ color: "white" }}
                  onClick={() => setActiveNavItem("Billing")}
                > */}
                 <button
  type="button"
  ref={posButtonRef}
  onClick={handlePosClick}
  title="Ctrl + B" // This will show "Ctrl + B" on hover
  className="bg-lime-500 w-14 rounded h-8 border-none mr-4 text-black cursor-pointer hover:bg-lime-400 h-4"
>
  POS
</button>

                {/* </Link> */}
              </li>
            </div>

            <div className="mt-2 ">
              <p
                style={{ color: "rgba(98, 98, 98, 1)" }}
                className="mt-2 font-bold"
              >
                {userName}
              </p>
            </div>
            <div
              className={`flex items-center ${activeNavItem === "Help & Support"
                ? "bg-lime-500 rounded-md w-36"
                : ""
                }`}
            >
              <button
                onClick={handleClick}
                className="flex items-center py-2 px-4 hover:bg-lime-500  rounded-md cursor-pointer"
              >
                <FaSignOutAlt size={30} />
              </button>
              {showPopup && (
                <div
                  className="absolute right-0 top-0 mt-16 p-2 bg-white border border-gray-300 shadow-md rounded-md"
                  ref={popupRef}
                >
                  <span
                    style={{ color: "Black" }}
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("role");
                      localStorage.removeItem("userName");
                      localStorage.removeItem("modelNames");
                      window.location.replace("/");
                      setShowPopup(false); // Close popup after logout
                    }}
                    className="truncate flex items-center py-1 px-4 cursor-pointer"
                  >
                    Logout
                  </span>
                </div>
              )}
            </div>
          </div>
        </header>
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;