import React, { useState, useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import tickImage from '../Assets/tableicon/check-mark.png';

const CreateQualityCheckingPopup = ({ onClose, toastMessage }) => {
    const [items, setItems] = useState([]);
    const [products, setProducts] = useState([]);
    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState({});
    const [quantity, setQuantity] = useState({});
    const [returnQuantity, serReturnQuantity] = useState({});
    const [price, setPrice] = useState({});
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState('');
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectDate, setSelectDate] = useState();
    const [availableProducts, setAvailableProducts] = useState([]);

    useEffect(() => {
        const fetchPurchaseOrders = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/purchaseDetails/purchaseOrder', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.status === 200) {
                    setPurchaseOrders(result.data);
                } else {
                    console.error('Failed to fetch purchase orders:', result.message);
                }
            } catch (error) {
                console.error('Error fetching purchase orders:', error);
            }
        };
        fetchPurchaseOrders();
    }, []);


    useEffect(() => {
        const fetchWarehouses = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch('https://pos.farm2bag.com/api/v1/warehouse', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.status === 200) {
                    setWarehouses(result.data);
                } else {
                    console.error('Failed to fetch warehouses:', result.message);
                }
            } catch (error) {
                console.error('Error fetching warehouses:', error);
            }
        };
        fetchWarehouses();
    }, []);

    useEffect(() => {
        setFilteredProducts(products.filter(product =>
            product.name.toLowerCase().includes(searchQuery.toLowerCase())
        ));
    }, [searchQuery, products]);


    // Update available products when the selected purchase order changes
    useEffect(() => {
        if (selectedPurchaseOrder) {
            const selectedOrder = purchaseOrders.find(order => order.id === selectedPurchaseOrder);
            if (selectedOrder) {
                setAvailableProducts(selectedOrder.products.map(product => ({
                    id: product.productId,
                    name: product.productName,
                    orderQuantity: product.orderQuantity
                })));
            }
        } else {
            setAvailableProducts([]);
        }
    }, [selectedPurchaseOrder, purchaseOrders]);


    const handleCheckboxChange = (product) => {
        setSelectedProducts((prev) => {
            const updated = { ...prev };
            if (updated[product.id]) {
                delete updated[product.id];
                setItems((prevItems) => prevItems.filter((item) => item.id !== product.id));
            } else {
                updated[product.id] = product;
                setItems((prevItems) => [
                    ...prevItems,
                    {
                        ...product,
                        quantity: '',
                        returnQuantity: '',
                        price: '',
                        orderQuantity: product.orderQuantity, // Add this line
                        status: 'OK', // Default status
                    },
                ]);
            }
            return updated;
        });
    };


    const removeItem = (index) => {
        const removedItem = items[index];
        setItems(items.filter((_, i) => i !== index));
        setSelectedProducts(prev => {
            const updated = { ...prev };
            delete updated[removedItem.id];
            return updated;
        });
    };

    const formatLocalDate = (date) => {
        const pad = (num) => num.toString().padStart(2, '0');
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1); // Months are zero-indexed
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleInputChange = (index, field, value) => {
        setItems(prevItems => {
            const updatedItems = [...prevItems];
            const currentItem = updatedItems[index];

            if (field === 'quantity') {
                const newValue = Number(value);
                const orderQuantity = currentItem.orderQuantity;

                if (newValue > orderQuantity) {
                    // Prevent quantity from exceeding order quantity
                    return prevItems; // Do not update
                }

                currentItem.quantity = newValue;

                // If quantity equals order quantity, set returnQuantity to 0
                if (newValue === orderQuantity) {
                    currentItem.returnQuantity = 0;
                } else {
                    // Set return quantity to the difference between order quantity and entered quantity
                    currentItem.returnQuantity = orderQuantity - newValue;
                }
            } else {
                currentItem[field] = value; // Handle other fields
            }

            return updatedItems;
        });
    };


    const handleStatusChange = (index, value) => {
        const newItems = [...items];
        newItems[index].status = value;
        setItems(newItems);
    };

    const create = async () => {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');

        // Validation
        if (!selectedWarehouse || !selectDate || !invoiceNumber || !selectedPurchaseOrder || items.length === 0) {
            alert("Please fill in all required fields and add at least one product.");
            return;
        }
        if (items.some(item => !item.quantity || !item.price)) {
            alert("Please ensure all products have quantity and price.");
            return;
        }

        const payload = {
            warehouseId: selectedWarehouse,
            invoiceNo: invoiceNumber,
            purchaseCode: selectedPurchaseOrder,
            invoiceDate: selectDate,
            product: items.map(item => ({
                productId: item.id,
                quantity: item.quantity,
                price: item.price,
                returnQuantity: item.returnQuantity,
                status: item.status
            })),
            createdBy: role,
        };

        try {
            const response = await fetch('https://pos.farm2bag.com/api/v1/qualityChecking', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });
            const result = await response.json();
            if (result.status === 201) {
                toastMessage(result.message || 'Quality check created successfully!');

                setItems([]);
                setProducts([]);
                setPurchaseOrders([]);
                setWarehouses([]);
                setSelectedProducts({});
                setQuantity({});
                serReturnQuantity({});
                setPrice({});
                setSelectDate();
                setInvoiceNumber('');
                setSelectedPurchaseOrder('');
                setSelectedWarehouse('');
                setSearchQuery('');
                setFilteredProducts([]);
                if (onClose) {
                    onClose();
                }
            } else {
                console.error('Failed to create quality check:', result.message);
            }
        } catch (error) {
            console.error('Error creating quality check:', error);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-75 z-50">
            <div className="bg-white w-11/12 h-full overflow-y-auto p-6">
                <h2 className="text-2xl font-bold py-2 text-white bg-gradient-to-r from-green-600 to-lime-500 !important">Create Quality Checking</h2>
                <div className="grid grid-cols-3 gap-4 bg-gradient-to-r from-yellow-400 to-yellow-400">

                    <div className="flex items-center mb-4 mt-1">
                        <label className="block font-bold w-1/3 text-right pr-4"> Purchase Order</label>
                        <select
                            className={`block w-56 w-2/3 py-2 px-3 border-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent ${selectedPurchaseOrder ? 'border-green-500 bg-green-400' : 'border-red-500 bg-red-500 text-white'
                                }`}
                            value={selectedPurchaseOrder}
                            onChange={(e) => setSelectedPurchaseOrder(e.target.value)}
                        >
                            <option value="" className='hidden'>Select Purchase Order</option>
                            {purchaseOrders.map(order => (
                                <option key={order.id} value={order.id}>
                                    {order.purchaseOrder}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex items-center mb-4 mt-1">
                        <label className="block font-bold w-2/3 text-center pr-4">Invoice Date & Time</label>
                        <input
                            type="datetime-local"
                            value={selectDate ? formatLocalDate(selectDate) : ''}
                            onChange={(e) => setSelectDate(new Date(e.target.value))}
                            className={`block w-56 w-2/3 py-2 px-3 border-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent ${selectDate ? 'border-green-500 bg-green-400' : 'border-red-500 bg-red-500 text-white'
                                }`}
                        />
                    </div>

                    <div className="flex items-center mb-4 mt-1">
                        <label className="block font-bold w-1/3 text-right pr-4">Invoice Number</label>
                        <input
                            type="text"
                            value={invoiceNumber}
                            onChange={(e) => setInvoiceNumber(e.target.value)}
                            placeholder="Invoice Number"
                            className={`block w-56 w-2/3 py-2 px-3 border-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent ${invoiceNumber ? 'border-green-500 bg-green-400' : 'border-red-500 bg-red-500 text-white'
                                }`}
                        />
                    </div>

                    <div className="flex items-center mb-4">
                        <label className="block font-bold w-1/3 text-right pr-4"> Warehouse</label>
                        <select
                            className={`block w-56 w-2/3 py-2 px-3 border-2 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent ${selectedWarehouse ? 'border-green-500 bg-green-400' : 'border-red-500 bg-red-500 text-white'
                                }`}
                            value={selectedWarehouse}
                            onChange={(e) => setSelectedWarehouse(e.target.value)}
                        >
                            <option value="">Select Warehouse</option>
                            {warehouses.map(warehouse => (
                                <option key={warehouse.id} value={warehouse.id}>
                                    {warehouse.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex items-center mb-4">
                        <label className="block font-bold w-1/3 text-right pr-4">Search Product</label>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search for product..."
                            className="border border-gray-300 rounded w-2/3 py-2 px-3"
                        />
                    </div>

                    <div className="flex items-center justify-evenly ml-20 mb-4">
                        <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700">
                            Close
                        </button>
                        <button onClick={create} className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700">
                            Create
                        </button>
                    </div>

                </div>

                <div className='w-full h-64 overflow-auto bg-white p-4'>
                    <h3 className="font-bold text-lg mb-2">Available Products</h3>
                    <div className="grid grid-cols-4 gap-4 ">
                        {availableProducts.map((product) => (
                            <div
                                key={product.id}
                                className={`flex items-center border border-gray-200 rounded-lg p-2 ${selectedProducts[product.id] ? 'bg-green-300' : 'bg-white'}`}>
                                <input type="checkbox" id={product.id} checked={!!selectedProducts[product.id]} onChange={() => handleCheckboxChange(product)} className="mr-2" />
                                <label htmlFor={product.id} className="text-sm font-semibold"> {product.name} </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mb-6">
                    <h4 className="font-bold text-lg mb-2">Product Table</h4>
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Name</th>
                                <th className="py-2 px-4 border-b">Purchase Order Quantity</th>
                                <th className="py-2 px-4 border-b">Price</th>
                                <th className="py-2 px-4 border-b items-center flex justify-center"><img src={tickImage} className='w-5 h-5 text-center mr-2' /> (QTY)</th>
                                <th className="py-2 px-4 border-b">❌ (QTY)</th>
                                <th className="py-2 px-4 border-b">Status</th>
                                <th className="py-2 px-4 border-b">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b">{item.name}</td>
                                    <td className="py-2 px-4 border-b">{item.orderQuantity}</td>
                                    <td className="py-2 px-4 border-b">
                                        <input
                                            type="number"
                                            value={item.price}
                                            onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                            className={`border-2 ${item.price ? 'border-[#07fa02]' : 'border-red-300'} rounded w-full py-2 px-3`}
                                        />
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <input
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                            className={`border-2 ${item.quantity ? 'border-[#07fa02]' : 'border-red-300'} rounded w-full py-2 px-3`}
                                        />
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <input
                                            type="number"
                                            value={item.returnQuantity}
                                            onChange={(e) => handleInputChange(index, 'returnQuantity', e.target.value)}
                                            className={`border-2 ${item.returnQuantity ? 'border-[#07fa02]' : 'border-red-300'} rounded w-full py-2 px-3`} 
                                        />
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <select
                                            value={item.status}
                                            onChange={(e) => handleStatusChange(index, e.target.value)}
                                            className={`border-2 rounded-lg w-full py-2 px-3 ${item.status === "OK" ? "border-white bg-green-400" : "border-white bg-red-400"}`}>
                                            <option value="OK">Update Stock</option>
                                            <option value="Wastage">Add QC (Only)</option>
                                        </select>
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <button onClick={() => removeItem(index)} className="text-red-500">
                                            <IoMdClose size={24} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CreateQualityCheckingPopup;