import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdTrash } from "react-icons/io";
import { FaRegEdit } from "react-icons/fa";
import filterIcon from "../Assets/tableicon/filter.png";
import defaultImage from "../Assets/login/f2bimage.png";
import { uploadImageToS3 } from "../components/Aws";
import CreateProduct from "../pages/CreateProduct";
import Papa from "papaparse";
import ProductNotImport from "../components/ProuctNotImport";
import ReactSearchInput from "react-search-input";
import * as XLSX from "xlsx";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { FaExclamationTriangle } from "react-icons/fa"; // Importing the important icon

const Products = () => {
  const [loading, setLoading] = useState(true);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
  const [newProductName, setNewProductName] = useState("");
  const [updateProductName, setUpdateProductName] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [image, setImage] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [updateProductCode, setUpdateProductCode] = useState("");
  const [storedPage, setStoredPage] = useState(1); // New state for storing current page
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [selectedBrandId, setSelectedBrandId] = useState("");
  const[ SelectedBrandIdFilter, setSelectedBrandIdFilter]=useState("");
  const[SelectedCategoryIdFilter,setSelectedCategoryIdFilter]=useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brandName, setBrandName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  // Array to  store the  unImported products
  const [errorMessages, setErrorMessages] = useState([]);

  const [totalCategories, setTotalCategories] = useState(0);
  const [totalBrands, setTotalBrands] = useState(0);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // edit popUp code
  useEffect(() => {
    fetchBrands();
    fetchCategories();
  }, []);
  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://pos.farm2bag.com/api/v1/categories",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        const categories = response.data.data;
        setCategories(categories);

        // Count total category names and console it
        setTotalCategories(categories.length);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchBrands = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("https://pos.farm2bag.com/api/v1/brand", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const brands = response.data.data;
        setBrands(brands);
        setTotalBrands(brands.length);
      }
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  // edit popUp code

  useEffect(() => {
    // Simulate fetching product data
    setTimeout(() => {
      setLoading(false); // Set loading to false after data is fetched (simulated delay)
    }, 800);
  }, []);

  useEffect(() => {
    if (SelectedCategoryIdFilter) {
      // If a category ID is selected, fetch products by category
      const token = localStorage.getItem("token");

      axios
        .get(
          `https://pos.farm2bag.com/api/v1/products/category/${SelectedCategoryIdFilter}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setProducts(response.data.data);
            console.log("Products:", response.data.data);
          } else {
            console.error("Failed to fetch products:", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    } else {
      // If no category ID is selected, fetch all products
      fetchData();
    }
  }, [SelectedCategoryIdFilter]);
  const handleBrandChange = async (brandId) => {
    if (!brandId) {
      fetchData(); // Call fetchData() if no brand is selected
      return; // Exit the function
    }
  
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("token"); // Replace 'token' with your actual token key
  
      const response = await axios.get(
        `https://pos.farm2bag.com/api/v1/products/brand/${brandId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );
  
      if (response.data.status === 200) {
        setProducts(response.data.data); // Store the products in state
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle error appropriately (e.g., display an error message)
    }
  };
  

  useEffect(() => {
    setOriginalProducts(products);
  }, [products]);

  useEffect(() => {
    if (searchQuery === "") {
      fetchData(); // Fetch initial data again
    }
  }, [searchQuery]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in local storage");
        toast.error("No token found in local storage");
        return;
      }

      const response = await fetch(
        "https://pos.farm2bag.com/api/v1/products/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      const products = responseData.data;

    // Sort products by createdAt in descending order
    const sortedProducts = products.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      // Directly use products if brandName is already included
      setProducts(sortedProducts);
      // toast.success('Products fetched successfully');
    } catch (error) {
      console.error("Error fetching data:", error);
      // toast.error('Error fetching products');
    }
  };

  const handleSort = (key) => {
    const sortedProducts = [...products].sort((a, b) => {
      if (key === 'createdAt') {
        // Sort by createdAt in descending order
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else {
        // Sort alphabetically by other fields
        return a[key].localeCompare(b[key]);
      }
    });
  
    setProducts(sortedProducts);
    setSortBy(key);
  };

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    setStoredPage(pageNumber); // Update storedPage when page changes
  };
  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };
  const handleEdit = (product) => {
    setSelectedProduct(product);
    setUpdateProductName(product.name);

    setUpdateProductCode(product.productsCode);
    setBrandName(product.brandName);

    setSelectedBrandId(product.brandId);
    setBrandName(product.brandName);
    setSelectedCategoryId(product.categoryId);
    setCategoryName(product.categoryName);

    setEditPopupOpen(true);
  };

  const handleUpdateProduct = async () => {
    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");

      if (!token) {
        console.error("No token found in local storage");
        toast.error("No token found in local storage");
        return;
      }

      const id = selectedProduct.id;

      if (!updateProductName.trim()) {
        toast.error("Product name cannot be empty");
        return;
      }
      if (!updateProductCode.trim()) {
        toast.error("ProductCode cannot be empty");
        return;
      }

      let imageUrl = "";
      if (image) {
        imageUrl = await uploadImageToS3(image);
      }

      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/products/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: updateProductName,
            modifiedBy: role,
            image: imageUrl || selectedProduct.image || defaultImage,
            brandId: selectedBrandId,
            productCategoryId: selectedCategoryId,
            productsCode: updateProductCode,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update product");
      }
      setBrandName("");
      setCategoryName("");
      setEditPopupOpen(false);
      setUpdateProductName("");
      setImage(null);
      fetchData();
      toast.success("Product updated successfully");
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Error updating product");
    }
  };

  useEffect(() => {
    if (isEditPopupOpen && selectedProduct) {
      setUpdateProductName(selectedProduct.name);
      setSelectedBrandId(selectedProduct.brandId);
      setBrandName(selectedProduct.brandName);
      setSelectedCategoryId(selectedProduct.categoryId);
      setCategoryName(selectedProduct.categoryName);
    }
  }, [isEditPopupOpen, selectedProduct]);

  const handleDelete = (product) => {
    setSelectedProduct(product);
    setDeletePopupOpen(true);
  };

  const handleDeleteProduct = async () => {
    try {
      const token = localStorage.getItem("token");
      const role = localStorage.getItem("role");

      if (!token) {
        console.error("No token found in local storage");
        toast.error("No token found in local storage");
        return;
      }

      const id = selectedProduct.id;
      console.log("delete", id);
      const response = await fetch(
        `https://pos.farm2bag.com/api/v1/products/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ modifiedBy: role }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete product");
      }

      // After successful deletion, fetch data to update the product list
      await fetchData();

      // Reset to the first page if there are no items on the current page after deletion
      if (currentItems.length === 1 && currentPage > 1) {
        setCurrentPage(currentPage - 1); // Move to the previous page
      }

      setDeletePopupOpen(false);
      toast.success("Product deleted successfully");
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Error deleting product");
    }
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const handleSearch = (term) => {
    const normalizedTerm = term.toLowerCase().trim();
    setSearchQuery(normalizedTerm);

    if (normalizedTerm === "") {
      setProducts(originalProducts);
      setCurrentPage(storedPage);
    } else {
      const filteredProducts = originalProducts.filter(
        (product) =>
          (product.productsCode &&
            product.productsCode.toLowerCase().includes(normalizedTerm)) ||
          (product.name && product.name.toLowerCase().includes(normalizedTerm))
      );
      setProducts(filteredProducts);
      setCurrentPage(1);
    }
  };

  const toggleCreatePopup = () => {
    setCreatePopupOpen(!isCreatePopupOpen);
  };

  const filteredProducts = products.filter(
    (product) =>
      (product.productsCode &&
        product.productsCode
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (product.name &&
        product.name.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const currentItems = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleAddProduct = () => {
    fetchData(); // Refresh product data after adding a new product
  };

  const handleExport = async () => {
    try {
      // Fetch the token from local storage or other storage method
      const token = localStorage.getItem("token"); // Adjust as needed

      // Fetch the data from the API
      const response = await fetch(
        "https://pos.farm2bag.com/api/v1/products/export",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Convert the response to a blob
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to download the file
      const a = document.createElement("a");
      a.href = url;
      a.download = "exported_products.xlsx"; // Desired file name
      document.body.appendChild(a);
      a.click();
      a.remove();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("There was an error fetching the data:", error);
    }
  };

  const handleFileImport = async (event) => {
    setLoading(true); // Show loading spinner
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found in local storage");
        toast.error("No token found in local storage");
        return;
      }

      const file = event.target.files[0];
      if (!file) {
        throw new Error("No file selected");
      }

      const data = await readFileAsync(file);
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(firstSheet);

      // Filter the data where "Update(Y/N)" is "Y"
      const filteredData = jsonData.filter((row) => row["Update(Y/N)"] === "Y");

      const fetchOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Fetch existing categories and brands from the database
      const [categoriesResponse, brandsResponse] = await Promise.all([
        fetch("https://pos.farm2bag.com/api/v1/categories", fetchOptions),
        fetch("https://pos.farm2bag.com/api/v1/brand", fetchOptions),
      ]);

      const [categories, brands] = await Promise.all([
        categoriesResponse.json(),
        brandsResponse.json(),
      ]);

      const existingCategories = categories.data;
      const existingBrands = brands.data;

      const getCategoryId = async (categoryName) => {
        const lowerCaseCategoryName = categoryName.toLowerCase();
        let category = existingCategories.find(
          (cat) => cat.categoryName.toLowerCase() === lowerCaseCategoryName
        );

        if (!category) {
          const createCategoryResponse = await fetch(
            "https://pos.farm2bag.com/api/v1/categories",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ categoryName }),
            }
          );

          if (createCategoryResponse.status === 409) {
            category = existingCategories.find(
              (cat) => cat.categoryName.toLowerCase() === lowerCaseCategoryName
            );
          } else {
            const newCategory = await createCategoryResponse.json();
            console.log(
              `Created new category '${categoryName}' ID: ${newCategory.data.id}`
            );
            category = newCategory.data;

            existingCategories.push(category);
          }
        } else {
          console.log(
            `Category '${categoryName}' already exists with ID: ${category.id}`
          );
        }

        return category.id;
      };

      const getBrandId = async (brandName) => {
        const lowerCaseBrandName = brandName.toLowerCase();
        let brand = existingBrands.find(
          (b) => b.brandName.toLowerCase() === lowerCaseBrandName
        );

        if (!brand) {
          const createBrandResponse = await fetch(
            "https://pos.farm2bag.com/api/v1/brand",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ brandName }),
            }
          );

          const newBrand = await createBrandResponse.json();
          console.log(
            `Created new brand '${brandName}' ID: ${newBrand.data.id}`
          );
          brand = newBrand.data;

          existingBrands.push(brand);
        } else {
          console.log(
            `Brand '${brandName}' already exists with ID: ${brand.id}`
          );
        }

        return brand.id;
      };

      const productsResponse = await fetch(
        "https://pos.farm2bag.com/api/v1/products",
        fetchOptions
      );
      const products = await productsResponse.json();
      const existingProducts = products.data;

      const processProduct = async (product) => {
        try {
          const existingProduct = existingProducts.find(
            (p) => p.name.toLowerCase() === product.name.toLowerCase()
          );

          let response;
          if (existingProduct) {
            response = await fetch(
              `https://pos.farm2bag.com/api/v1/products/${existingProduct.id}`,
              {
                method: "PUT",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(product),
              }
            );
          } else {
            if (product.id) {
              delete product.id;
            }

            response = await fetch("https://pos.farm2bag.com/api/v1/products", {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(product),
            });
          }

          const result = await response.json();

          // Check the message from the response for specific patterns
          if (
            result.message.includes("Product with code") ||
            result.message.includes("Product with brand names already exist")
          ) {
            setErrorMessages((prevMessages) => [
              ...prevMessages,
              result.message,
            ]);
            setModalIsOpen(true);
          } else {
            // console.log(`Processed product '${product.name}' ID: ${result.data?.id || 'N/A'}`);
          }

          // Log all stored error messages
          if (errorMessages.length > 0) {
            console.log("Error messages:", errorMessages);
          }

          return result.data;
        } catch (error) {
          console.error(`Error processing product '${product.name}':`, error);
        }
      };

      for (const row of filteredData) {
        if (row.categoryName) {
          row.productCategoryId = await getCategoryId(row.categoryName);
        }
        if (row.brandName) {
          row.brandId = await getBrandId(row.brandName);
        }

        const { categoryName, brandName, Productcode, ...productData } = row;

        // Add Productcode directly to the productData object
        if (Productcode) {
          productData.productsCode = Productcode;
        }

        const processedProduct = await processProduct(productData);
        console.log(
          `Processed product ID: ${processedProduct.id}, Category ID: ${processedProduct.productCategoryId}`
        );
      }

      console.log("Import process completed successfully");
      toast.success("Import process completed successfully");
      fetchData();
    } catch (error) {
      console.error("Error handling file import:", error);
      toast.error("Error handling file import");
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsBinaryString(file);
    });
  };

  return (
    <div className="ml-64 bg-white-100 mt-14 p-12 h-screen">
      <div className="container mx-auto">
        <div className="mb-10">
          <div className="relative w-8/12 ml-96 border border-gray-300 p-2 flex">
            <p>
              <span className="text-red-700 font-bold">Note:</span> Please
              import the Excel sheet with the following column names:
              <span className="text-green-800 font-semibold">
                {" "}
                name, categoryName, brandName, Unit, productsCode, Update(Y/N).
              </span>
            </p>
            <div className="absolute top-2 right-2 transform translate-x-full">
              <button onClick={openModal} className="text-red-600 ml-4 mt-16">
                <FaExclamationTriangle size={24} />
              </button>
            </div>
          </div>

          <h1 className="font-extrabold text-3xl text-left text-gray-500">
            Products
          </h1>
          <div className="mt-2 border-t border-gray-500"></div>
        </div>
        <div className="flex items-center mb-5">
          <div className="ml-0">
            <form>
              <div className=" flex">
                <ReactSearchInput
                  placeholder="🔍 Search the Product / Code"
                  value={searchQuery}
                  onChange={handleSearch}
                  style={{
                    padding: "6px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    width: "120%",
                    fontSize: "1rem",
                    outline: "none",
                    fontWeight: 600,
                  }}
                />
                <div className="flex">
                <select
  value={SelectedBrandIdFilter}
  onChange={(e) => {
    const brandId = e.target.value;
    setSelectedBrandIdFilter(brandId);
    handleBrandChange(brandId); // Call the function to fetch products
  }}
  className="border border-gray-300 px-4 py-2 rounded-lg mb-6 w-full ml-20"
>
  <option value="">
    Select a brand
  </option>
  {brands.map((brand) => (
    <option key={brand.id} value={brand.id}>
      {brand.brandName}
    </option>
  ))}
</select>

                  {/* categories */}
                  <select
                    value={SelectedCategoryIdFilter}
                    onChange={(e) => setSelectedCategoryIdFilter(e.target.value)}
                    className="border border-gray-300 px-4 py-1.5 rounded-lg mb-6 w-48 ml-5 "
                  >
                    <option value="">Select a category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.categoryName}{" "}
                        {/* Display categoryName in the dropdown */}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div className="flex ml-auto items-center space-x-4">
            <img
              src={filterIcon}
              alt="filter"
              className="w-12 h-10 rounded-lg cursor-pointer"
              onClick={() => handleSort("name")}
            />
            <button
              onClick={toggleCreatePopup}
              style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
              className="w-48 h-10 font-medium text-sm rounded-lg"
            >
              Add Product
            </button>
            <button
              onClick={handleExport}
              className="w-28 h-10 font-medium text-sm rounded-lg"
              style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
            >
              Export
            </button>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileImport}
              className="w-28 h-10 font-medium text-sm rounded-lg"
              style={{ display: "none" }}
              id="fileUpload"
            />
            <label
              htmlFor="fileUpload"
              style={{ backgroundColor: "rgba(66, 255, 97, 1)" }}
              className="w-28 h-10 font-medium text-sm rounded-lg text-center flex items-center justify-center cursor-pointer"
            >
              Import
            </label>
          </div>
        </div>

    

        <div className="rounded-lg overflow-hidden">
          {loading ? (
            <div className="flex flex-col items-center justify-center h-64">
              <RotatingLines
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                visible={true}
              />
              <h1 className="mt-4 mr-4">Wait for few seconds</h1>
            </div>
          ) : currentItems.length === 0 ? (
            <div className="text-center mt-4 text-gray-500">
              No products found.
            </div>
          ) : (
            <table className="table-auto w-full border-collapse border-gray-500 text-center">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-2 py-2 text-center pr-14 w-1/5">Image</th>
                  <th className="px-4 py-2 text-center w-1/5">Name</th>
                  <th className="px-4 py-2 text-center w-1/5">Product Code</th>
                  <th className="px-4 py-2 text-center w-1/5 ">Category</th>
                  <th className="px-4 py-2 text-center w-1/5">Brand</th>

                  <th className="px-4 py-2 text-center w-1/5">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((product, index) => (
                  <tr
                    key={product.id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}
                  >
                    <td className="py-2 border-gray-500 text-center pl-20">
                      <img
                        src={product.image || defaultImage}
                        alt="Product"
                        className="w-16 h-14"
                      />
                    </td>
                    <td className="px-4 py-2 border-gray-500">
                      {product.name}
                    </td>
                    <td className="px-4 py-2 border-gray-500">
                      {product.productsCode}
                    </td>
                    <td className="px-4 py-2 border-gray-500 ">
                      {product.categoryName}
                    </td>
                    <td className="px-4 py-2 border-gray-500">
                      {product.brandName}
                    </td>

                    <td className="px-4 py-2 border-gray-500">
                      <button
                        onClick={() => handleEdit(product)}
                        className="mr-2 font-bold rounded text-blue-500"
                      >
                        <FaRegEdit size={22} className="inline-block mr-1" />
                      </button>
                      <button
                        onClick={() => handleDelete(product)}
                        className="text-white font-bold rounded"
                      >
                        <IoMdTrash
                          size={30}
                          style={{ color: "red" }}
                          className="inline-block mr-1"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {modalIsOpen && (
          <ProductNotImport
            errorMessages={errorMessages}
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
          />
        )}

        {isCreatePopupOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-60 flex justify-center items-center">
            <CreateProduct
              onClose={() => setCreatePopupOpen(false)}
              onAddProduct={handleAddProduct}
            />
          </div>
        )}

        {isEditPopupOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center mt-10">
            <div className="bg-gray-200 w-full max-w-md p-6 rounded-lg">
              <h2 className="text-xl font-bold mb-6 text-center">
                Edit Product
              </h2>

              <input
                type="text"
                value={updateProductName}
                onChange={(e) => setUpdateProductName(e.target.value)}
                placeholder="Enter new product name"
                className="border border-gray-300 px-4 py-2 rounded-lg mb-6 w-full"
              />

              <input
                type="text"
                value={updateProductCode}
                onChange={(e) => setUpdateProductCode(e.target.value)}
                placeholder="Enter new product code"
                className="border border-gray-300 px-4 py-2 rounded-lg mb-6 w-full"
              />

              <div className="p-2 bg-white rounded-lg mb-6">
                <input
                  type="file"
                  id="image"
                  name="image"
                  onChange={handleImageChange}
                  className="block w-full"
                />
              </div>

 <select
  value={selectedBrandId}
  onChange={(e) => {
    const brandId = e.target.value;
    setSelectedBrandId(brandId);

  }}
  className="border border-gray-300 px-4 py-2 rounded-lg mb-6 w-full"
>
  <option value="" disabled>
    Select a brand
  </option>
  {brands.map((brand) => (
    <option key={brand.id} value={brand.id}>
      {brand.brandName}
    </option>
  ))}
</select>



              <select
                value={selectedCategoryId}
                onChange={(e) => setSelectedCategoryId(e.target.value)}
                className="border border-gray-300 px-4 py-2 rounded-lg mb-6 w-full"
              >
                <option value="" disabled>
                  Select a category
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.categoryName}
                  </option>
                ))}
              </select>

              <div className="flex justify-between mt-8">
                <button
                  onClick={() => {
                    setEditPopupOpen(false);
                  }}
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleUpdateProduct();
                  }}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        )}

        {isDeletePopupOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg">
              <h2 className="text-xl font-bold mb-4">Delete Product</h2>
              <p>
                Are you sure you want to delete the product "
                <span className="font-semibold text-red-600">
                  {selectedProduct.name}
                </span>
                "?
              </p>
              <button
                onClick={() => setDeletePopupOpen(false)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg float-left mr-11 mt-10 hover:bg-red-600"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteProduct}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg ml-2 float-right ml-11 mt-10 hover:bg-green-500"
              >
                Delete
              </button>
            </div>
          </div>
        )}

        <div className="flex justify-between items-center mt-10 pb-10">
          <div>
            <label htmlFor="itemsPerPage">Records per page: </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
            </select>
          </div>

          <p>Total Categories: {totalCategories}</p>
          <p>Total Brands: {totalBrands}</p>

          <div className="flex justify-center">
            <button
              onClick={() => handlePagination(currentPage - 1)}
              disabled={currentPage === 1}
              className="mx-1 px-3 py-1 bg-gray-300 hover:bg-gray-400 rounded disabled:bg-gray-200"
            >
              Previous
            </button>
            <span className="mx-2 px-3 py-1">
              Page {currentPage} of{" "}
              {Math.ceil(filteredProducts.length / itemsPerPage)}
            </span>
            <button
              onClick={() => handlePagination(currentPage + 1)}
              disabled={
                currentPage ===
                Math.ceil(filteredProducts.length / itemsPerPage)
              }
              className="mx-1 px-3 py-1 bg-gray-300 hover:bg-gray-400 rounded disabled:bg-gray-200"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    
      <ToastContainer position="bottom-center" className="ml-20 mb-11" />
     
    </div>
  );
};

export default Products;
