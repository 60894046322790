import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles
import SalesEditsPopup from './SalesEditsPopup';
import BillingActions from './SalesBillingActions';

const SalesEdit = ({ isOpen, onClose, initialData, fetchBillingData }) => {
    const [editData, setEditData] = useState({
        date: '',
        warehouse: '',
        customer: '',
        orderItems: []
    });

    const [matchingWarehouseStocks, setMatchingWarehouseStocks] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [overallTotal, setOverallTotal] = useState(0);
    const [billingItemIds, setBillingItemIds] = useState([]);
    const [billData, setBillData] = useState(null);
    const [invoicePopupOpen, setInvoicePopupOpen] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [shippingCharge, setShippingCharge] = useState(0);
    const [finalTotal, setFinalTotal] = useState(0);
    const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedItemName, setSelectedItemName] = useState('');
    const [stocks, setStocks] = useState([]);
    const [deleteButtonProductIds, setDeleteButtonProductIds] = useState([]);
    const [removedProductsTotal, setRemovedProductsTotal] = useState(0);
    const [paymentMode, setPaymentMode] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [pendingStatus, setPendingStatus] = useState('');
    const [exitsPaymentMode, setExitsPaymentMode] = useState('');
    const [newProductsCount, setNewProductsCount] = useState(0); //no use
    const [exitsReturnCount, setExitsReturnCount] = useState(0);
    const [oldTotalPrice, setOldTotalPrice] = useState(0);
    const [overAllReturnTotal, setOverAllReturnTotal] = useState(0);
    const [purchaseProductCount, setPurchaseProductCount] = useState(0);
    const [gst, setGst] = useState();


    const handleRemoveItem = (index, status) => {
        setSelectedItemIndex(index);
        setSelectedStatus(status);
        setSelectedItemName(editData.orderItems[index].name);
        setConfirmationPopupOpen(true);
    };

    useEffect(() => {
        let total = 0;
        let removedTotal = 0;

        editData.orderItems.forEach(orderItem => {
            if (orderItem.productStatus === 'Removed') {
                removedTotal += orderItem.quantity * (orderItem.price || 0);
            } else if (orderItem.productStatus !== 'Wastage') {
                total += orderItem.quantity * (orderItem.price || 0);
            }
        });

        setOverallTotal(total);
        setRemovedProductsTotal(removedTotal);
        setDiscount(editData.discountfetch);
        setShippingCharge(editData.shippingChargefetch);
        setGst(editData.gst);
    }, [editData.orderItems]);


    // useEffect(() => {
    //     const discountAmount = (overallTotal * discount) / 100;
    //     const withDiscount = (overallTotal - discountAmount);
    //     const gstAmount = (withDiscount * gst);
    //     setFinalTotal(gstAmount + shippingCharge);
    // }, [overallTotal, discount, shippingCharge, gst]);

    useEffect(() => {
        // Calculate the discount amount and the subtotal after discount
        const discountAmount = (overallTotal * discount) / 100;
        const discountedTotal = overallTotal - discountAmount;
    
        // Calculate the GST amount based on the discounted subtotal
        const gstAmount = (discountedTotal * gst) / 100;
    
        // Final total includes the discounted subtotal, GST, and shipping charge
        setFinalTotal(discountedTotal + gstAmount + shippingCharge);
    }, [overallTotal, discount, shippingCharge, gst]);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found in local storage');
                    return;
                }

                const response = await fetch(`https://pos.farm2bag.com/api/v1/billing/Itemsall/${initialData.billing.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch billing details');
                }

                const responseData = await response.json();
                const fetchedData = responseData.data;

                // Extract billingItems ids only
                const billingItemIds = fetchedData.billingItems.map(billingItem => billingItem.id);
                setBillingItemIds(billingItemIds); // Store billingItemIds in state
                // Parse invoiceDate in DD/MM/YYYY format and convert to Date object
                const [day, month, year] = fetchedData.invoiceDate.split('/');
                const invoiceDate = new Date(year, month - 1, day).toLocaleDateString('en-CA');

                const exitsPayMode = fetchedData.paymentMode;
                setExitsPaymentMode(exitsPayMode);

                const exitspending = fetchedData.status;
                setPendingStatus(exitspending);
                // Set old total price
                setOldTotalPrice(fetchedData.totalPrice);

                // Calculate the total price of products with productStatus "Removed"
                const removedProductsTotalPrice = fetchedData.billingItems
                    .flatMap(billingItem => billingItem.product)
                    .filter(product => product.productStatus === 'Removed')
                    .reduce((sum, product) => sum + product.totalPrice, 0);

                // Set the total price of removed products in the exitsReturnCount state
                setExitsReturnCount(removedProductsTotalPrice);

                //Blow given method was particular billing id value get and then set the usestate the value to display
                // Update the state with billingItem ids and additional fields
                setEditData({
                    date: invoiceDate,
                    invoice: fetchedData.invoice,
                    gst: fetchedData.gst,
                    discountfetch: fetchedData.discount,
                    shippingChargefetch: fetchedData.shippingCharges,
                    paymentMode: fetchedData.paymentMode,
                    status: fetchedData.status,
                    warehouse: fetchedData.warehouseName,
                    customer: fetchedData.customerDetails.name,
                    orderItems: fetchedData.billingItems.flatMap(billingItem => (
                        billingItem.product.map(product => ({
                            productId: product.productId,
                            name: product.productName,
                            unit: product.unit,
                            unitId: product.unitId,
                            price: product.totalPrice / product.quantity,
                            quantity: product.quantity,
                            productStatus: product.productStatus,
                            id: billingItemIds
                        }))
                    ))
                });

                const stocksResponse = await fetch('https://pos.farm2bag.com/api/v1/stocks', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!stocksResponse.ok) {
                    throw new Error('Failed to fetch stocks');
                }

                const stocksData = await stocksResponse.json();
                const matchedStocks = stocksData.data.filter(stock => stock.wareHouseId === fetchedData.warehouseId);

                let matchedProducts = [];
                matchedStocks.forEach(stock => {
                    matchedProducts = [...matchedProducts, ...stock.products];
                });

                setMatchingWarehouseStocks(matchedProducts);
                setStocks(matchedProducts);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (isOpen && initialData) {
            fetchData();
        }

    }, [isOpen, initialData]);


    const getProductQuantity = (productId) => {
        const stockProduct = stocks.find(product => product.productId === productId);
        return stockProduct ? stockProduct.quantity : 0;
    };

    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (query.trim() === '') {
            setFilteredProducts([]);
        } else {
            // Filter based on both product name and productsCode
            const lowercasedQuery = query.toLowerCase();

            const filtered = matchingWarehouseStocks.filter(product =>
            (product.name?.toLowerCase().includes(lowercasedQuery) ||
                product.productsCode?.toLowerCase().includes(lowercasedQuery))

                // product.name.toLowerCase().includes(query) ||
                // product.productsCode.toLowerCase().includes(query)
            );
            setFilteredProducts(filtered);
        }
    };

    const clearSearchResults = () => {
        setSearchQuery('');
        setFilteredProducts([]);
    };

    const handleProductSelection = (product) => {
        // Check if the product already exists in orderItems
        const existingOrderItem = editData.orderItems.find(item => item.productId === product.productId);

        if (existingOrderItem) {
            alert('Product is already added to the order.');
            return; // Exit the function if product already exists
        }

        const newOrderItem = {
            productId: product.productId,
            name: product.name,
            unit: product.unitSymbol,
            price: product.price,
            quantity: 1,
            totalPrice: product.price,
            id: null,
            newlyAdded: true
        };

        setEditData(prevState => ({
            ...prevState,
            orderItems: [...prevState.orderItems, newOrderItem]
        }));
        setDeleteButtonProductIds(prevIds => [...new Set([...prevIds, product.productId])]);
        // Update the new products count
        setNewProductsCount(prevCount => prevCount + 1);
        // clearSearchResults();
    };

    const isProductSelected = (productId) => {
        return editData.orderItems.some(item => item.productId === productId);
    };

    const handleClosePopup = () => {
        setEditData({
            date: '',
            warehouse: '',
            customer: '',
            orderItems: []
        });
        onClose();
    };

    const handleQuantityChange = (index, value) => {
        const availableQuantity = getProductQuantity(editData.orderItems[index].productId);
        const currentQuantity = editData.orderItems[index].quantity;

        // Calculate the difference between the new value and the current quantity
        const difference = value - currentQuantity;

        if (difference > availableQuantity) {
            toast.warn('Available quantity not sufficient', {
                autoClose: 1500, // 1.5 seconds
            });
            return;
        }

        // Update the available quantity based on the difference
        updateAvailableQuantity(editData.orderItems[index].productId, availableQuantity - difference);

        // Update the order item quantity
        const updatedOrderItems = [...editData.orderItems];
        updatedOrderItems[index].quantity = value;
        updatedOrderItems[index].totalPrice = value * updatedOrderItems[index].price;

        // Set the updated editData
        setEditData({
            ...editData,
            orderItems: updatedOrderItems,
        });
    };

    const updateAvailableQuantity = (productId, newQuantity) => {
        setStocks(prevStocks => {
            return prevStocks.map(stock => {
                if (stock.productId === productId) {
                    return {
                        ...stock,
                        quantity: newQuantity,
                    };
                }
                return stock;
            });
        });
    };

    const incrementQuantity = index => {
        const orderItem = editData.orderItems[index];
        const availableQuantity = getProductQuantity(orderItem.productId);

        if (availableQuantity <= 0) {
            toast.warn('Available quantity not available', {
                autoClose: 1500,
            });
            return;
        }

        const newOrderItems = [...editData.orderItems];
        newOrderItems[index].quantity += 1;
        newOrderItems[index].totalPrice = newOrderItems[index].quantity * newOrderItems[index].price;

        updateAvailableQuantity(orderItem.productId, availableQuantity - 1);

        setEditData({ ...editData, orderItems: newOrderItems });
    };

    const decrementQuantity = index => {
        const orderItem = editData.orderItems[index];
        const currentQuantity = orderItem.quantity;

        if (currentQuantity <= 1) return;

        const newOrderItems = [...editData.orderItems];
        newOrderItems[index].quantity -= 1;
        newOrderItems[index].totalPrice = newOrderItems[index].quantity * newOrderItems[index].price;

        const availableQuantity = getProductQuantity(orderItem.productId);
        updateAvailableQuantity(orderItem.productId, availableQuantity + 1);

        setEditData({ ...editData, orderItems: newOrderItems });
    };

    const handleDeleteItem = (index) => {
        const newOrderItems = [...editData.orderItems];
        newOrderItems.splice(index, 1); // Remove the item at the specified index
        setEditData({ ...editData, orderItems: newOrderItems });
        // Optionally reset the delete button IDs if needed
        setDeleteButtonProductIds(prevIds => prevIds.filter(id => id !== editData.orderItems[index].productId));
    };

    // Calculate subtotals
    const subtotals = deleteButtonProductIds.map((productId) => {
        const item = editData.orderItems.find(orderItem => orderItem.productId === productId);
        return item ? (item.quantity * item.price) : 0;
    });

    // Calculate overall total
    const overallSubtotal = subtotals.reduce((total, subtotal) => total + subtotal, 0).toFixed(2);

    useEffect(() => {
        const newlyAddedCount = editData.orderItems.filter(item => item.newlyAdded).length;
        setNewProductsCount(newlyAddedCount);
    }, [editData.orderItems]);




    const confirmRemoveItem = () => {
        const index = selectedItemIndex;
        const status = selectedStatus;
        const newOrderItems = [...editData.orderItems];
        newOrderItems[index].productStatus = status;
        setEditData({ ...editData, orderItems: newOrderItems });

        // Recalculate overall total excluding Removed and Wastage items
        let total = 0;
        newOrderItems.forEach(orderItem => {
            if (orderItem.productStatus !== 'Removed' && orderItem.productStatus !== 'Wastage') {
                total += orderItem.quantity * (orderItem.price || 0);
            }
        });
        setOverallTotal(total);
        setConfirmationPopupOpen(false);
    };

    const cancelRemoveItem = () => {
        setConfirmationPopupOpen(false);
    };

    const formatDateToDDMMYYYY = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleRemoveAll = (e) => {
        // Prevent the default form submission behavior
        e.preventDefault();

        setEditData(prevData => ({
            ...prevData,
            orderItems: prevData.orderItems.map(item => ({
                ...item,
                productStatus: 'Removed',
            })),
        }));
    };



    const handleUpdate = async (e) => {
        e.preventDefault();
        const currentFormattedDate = formatDateToDDMMYYYY(new Date());

        try {
            const token = localStorage.getItem('token');
            const role = localStorage.getItem('role');

            if (!token) {
                console.error('No token found in local storage');
                return;
            }

            const orderItems = editData.orderItems.map((item) => {
                return {
                    ...item,
                    totalPrice: parseFloat((item.quantity * item.price).toFixed(2)),
                };
            });


            // Map the order items to the product array with billingItemIds
            const products = editData.orderItems.map((item, index) => ({
                productId: item.productId,
                unit: item.unit,
                unitId: item.unitId,
                quantity: item.quantity,
                totalPrice: parseFloat((item.quantity * item.price).toFixed(2)),
                productStatus: item.productStatus || "Purchase",
                productName: item.name
            }));

            // Create the billing items array
            const billingItems = [{
                id: billingItemIds[0], // ID of the billing item
                product: products,
                createdAt: new Date().toISOString(),
                modifiedAt: new Date().toISOString(),
                modifiedBy: role,
                isDeleted: false
            }];

            // Prepare the update data
            const updateData = {
                createdAt: new Date().toISOString(),
                id: initialData.billing.id,
                customerId: initialData.billing.customerId,
                warehouseId: initialData.billing.warehouseId,
                gst: gst,
                shippingCharges: shippingCharge,
                discount: discount,
                totalPrice: parseFloat(finalTotal.toFixed(2)),
                paymentMode: paymentMode || editData.paymentMode,
                status: paymentStatus || editData.status,
                invoice: editData.invoice,
                invoiceDate: currentFormattedDate,
                modifiedBy: role,
                billingItems: billingItems
            };

            // Send the update request to the backend
            const response = await fetch(`https://pos.farm2bag.com/api/v1/billing/updateAll/${initialData.billing.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(updateData)
            });

            if (!response.ok) {
                throw new Error('Failed to update billing details');
            }
            // Fetch updated data from server after update
            const updatedResponse = await fetch(`https://pos.farm2bag.com/api/v1/billing/Itemsall/${initialData.billing.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!updatedResponse.ok) {
                throw new Error('Failed to fetch updated billing details');
            }

            const updatedData = await updatedResponse.json();
            const updatedBillingItemIds = updatedData.data.billingItems.map(billingItem => billingItem.id);
            // Filter out products with productStatus "Removed" from billingItems[0].product
            const filteredProducts = billingItems[0].product.filter(product => product.productStatus !== 'Removed' && product.productStatus !== 'Wastage');

            // Count the number of products with productStatus "Removed"
            const updateRemovedProductCount = billingItems[0].product.filter(product => product.productStatus === 'Removed').length;

        // Calculate the total price of products with productStatus "Removed"
        const billingItemsUpdated = updatedData.data.billingItems;
        const fullRemovedProductsTotalPrice = billingItemsUpdated[0].product
            .filter(product => product.productStatus === 'Removed')
            .reduce((sum, product) => sum + product.totalPrice, 0);



            // Count the number of products with productStatus "Purchase"
            const purchaseProductsCount = billingItems[0].product.filter(product => product.productStatus === 'Purchase').length;

            // Set the count of purchase products in the purchaseProductCount state
            setPurchaseProductCount(purchaseProductsCount);

            // Update billData with the updated invoice details
            const updatedBillData = {
                date: updateData.invoiceDate,
                createdAt: updatedData.data.createdAt,
                invoice: updateData.invoice,
                updateTotalPrice: updateData.totalPrice,
                productsTotal: parseFloat(overallTotal.toFixed(2)),
                gst: updateData.gst,
                discount: updateData.discount,
                shippingCharges: updateData.shippingCharges,
                paymentMode: updateData.paymentMode,
                status: updateData.status,
                warehouse: updatedData.data.warehouseName,
                customer: updatedData.data.customerDetails.name,
                orderItems: filteredProducts.map(product => ({
                    productId: product.productId,
                    name: product.productName,
                    unit: product.unit,
                    price: parseFloat((product.totalPrice / product.quantity).toFixed(2)),
                    quantity: product.quantity,
                    productStatus: product.productStatus,
                    id: updatedBillingItemIds
                }))
            };


            // Set updated billData and open the InvoicePopup
            setBillData(updatedBillData);
            setInvoicePopupOpen(true);
            fetchBillingData();
            // Clear editData
            setEditData({
                date: '',
                warehouse: '',
                customer: '',
                orderItems: []
            });

            // Calculate removedAllProductsTotal
            const removedAllProductsTotal = fullRemovedProductsTotalPrice - exitsReturnCount;

            await BillingActions({
                pendingStatus,
                updatedBillData,
                updateData,
                exitsPaymentMode,
                removedAllProductsTotal,
                token,
                updateRemovedProductCount,
                purchaseProductsCount,
                oldTotalPrice,
                overAllReturnTotal: fullRemovedProductsTotalPrice,
            });

        } catch (error) {
            console.error('Error updating billing details:', error);
        }
    };
    const handleUpdateButtonClick = () => {
        const hasZeroQuantity = editData.orderItems.some(item => item.quantity === 0);

        if (hasZeroQuantity) {
            toast("Quantity cannot be zero for any item.");
            return;
        }
    };

    return (
        <>

            <SalesEditsPopup
                isOpen={isOpen}
                handleUpdate={handleUpdate}
                handleClosePopup={handleClosePopup}
                editData={editData}
                handleSearchChange={handleSearchChange}
                searchQuery={searchQuery}
                filteredProducts={filteredProducts}
                handleProductSelection={handleProductSelection}
                isProductSelected={isProductSelected}
                clearSearchResults={clearSearchResults}
                deleteButtonProductIds={deleteButtonProductIds}
                handleDeleteItem={handleDeleteItem}
                getProductQuantity={getProductQuantity}
                decrementQuantity={decrementQuantity}
                incrementQuantity={incrementQuantity}
                handleRemoveItem={handleRemoveItem}
                gst ={gst}
                setGst ={setGst}
                discount={discount}
                setDiscount={setDiscount}
                shippingCharge={shippingCharge}
                setShippingCharge={setShippingCharge}
                removedProductsTotal={removedProductsTotal}
                overallTotal={overallTotal}
                finalTotal={finalTotal}
                invoicePopupOpen={invoicePopupOpen}
                setInvoicePopupOpen={setInvoicePopupOpen}
                billData={billData}
                confirmationPopupOpen={confirmationPopupOpen}
                confirmRemoveItem={confirmRemoveItem}
                cancelRemoveItem={cancelRemoveItem}
                selectedItemName={selectedItemName}
                selectedStatus={selectedStatus}
                handleQuantityChange={handleQuantityChange}
                handleUpdateButtonClick={handleUpdateButtonClick}
                paymentMode={paymentMode}
                setPaymentMode={setPaymentMode}
                paymentStatus={paymentStatus}
                setPaymentStatus={setPaymentStatus}
                subtotals={subtotals}
                overallSubtotal={overallSubtotal}
                handleRemoveAll={handleRemoveAll}
            />
        </>
    );

};

export default SalesEdit;