import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadImageToS3 } from '../components/Aws';
import defaultImage from '../Assets/login/f2bimage.png';

const CreateProducts = ({ onClose, onAddProduct }) => {
    const [formData, setFormData] = useState({
        name: '',
        image: null,
        categoryId: '',
        brandId: '',
        description: '',
        productsCode: '' // Add productsCode to formData
    });
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchCategoriesAndBrands();
    }, []);

    const fetchCategoriesAndBrands = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found in local storage');

            const [categoriesResponse, brandsResponse] = await Promise.all([
                fetch('https://pos.farm2bag.com/api/v1/categories', {
                    headers: { 'Authorization': `Bearer ${token}` }
                }),
                fetch('https://pos.farm2bag.com/api/v1/brand', {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
            ]);

            if (!categoriesResponse.ok) throw new Error(`HTTP error! Status: ${categoriesResponse.status}`);
            if (!brandsResponse.ok) throw new Error(`HTTP error! Status: ${brandsResponse.status}`);

            const categoriesData = await categoriesResponse.json();
            const brandsData = await brandsResponse.json();

            setCategories(Array.isArray(categoriesData) ? categoriesData : categoriesData.data);
            setBrands(Array.isArray(brandsData) ? brandsData : brandsData.data);
        } catch (error) {
            console.error('Error fetching categories or brands:', error);
            toast.error('Error fetching categories or brands');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prevState => ({ ...prevState, image: file }));
        }
    };

    
    
    const handleCreateProduct = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found in local storage');
    
            let imageUrl = formData.image ? await uploadImageToS3(formData.image) : defaultImage;
    
            const response = await fetch('https://pos.farm2bag.com/api/v1/products', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    image: formData.image ? imageUrl : defaultImage,
                    createdBy: localStorage.getItem('role'),
                    productCategoryId: formData.categoryId
                })
            });
    
            const responseData = await response.json();
    
            // Check for specific error messages and handle them
            if (responseData.message) {
                if (responseData.message.includes("Product with code")) {
                    toast.error(responseData.message);
                } 
                else if (responseData.message.includes("Product with brand names already exist")) 
                           {
                    toast.error(responseData.message);
                }
                 else if (responseData.message.includes("created successfully")) {
                    setIsLoading(false);
                    toast.success(responseData.message); 
                    onClose();
                    return true; // Indicate success
                }
            }
    
            // If an error message was found, set loading to false and return
            setIsLoading(false);
            return false; // Indicate failure if no success message
    
        } catch (error) {
            console.error('Error creating product:', error);
            toast.error('Error creating product');
            setIsLoading(false);
            return false;
        }
    };
    
    
    
    const handleCreateProductAndNotify = async () => {
        if (formData.name && formData.categoryId && formData.brandId && formData.description && formData.productsCode) {
            const success = await handleCreateProduct();
            if (success) {
                onAddProduct();
            }
        } else {
            toast.error('Please fill all required fields');
        }
    };

    const handleCancel = () => {
        onClose();
        setFormData({
            name: '',
            image: null,
            categoryId: '',
            brandId: '',
            description: '',
            productsCode: '' // Reset productsCode field on cancel
        });
    };

    return (
        <div className='bg-gray-100 ml-64 mt-20 p-12'>
            <div className="container mx-auto">
                <div className="mb-10">
                    <ul className='flex'>
                        <li><h1 className="font-extrabold text-3xl text-left text-gray-500">Add Product</h1></li>
                    </ul>
                    <div className="mt-2 border-t border-gray-500"></div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-x-7 gap-y-5'>
                    <div>
                        <label className='text-lg font-semibold' htmlFor="name">Name</label><br />
                        <input
                            className='p-2 w-full md:w-80 rounded-xl border-gray-500'
                            placeholder="Enter the Name"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label className='text-lg font-semibold' htmlFor="categoryId">Product Category</label><br />
                        <select
                            className='p-2 w-full md:w-80 rounded-xl border-gray-500'
                            id="categoryId"
                            name="categoryId"
                            value={formData.categoryId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Category</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.categoryName}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className='text-lg font-semibold' htmlFor="image">Image</label><br />
                        <input
                            className="bg-white p-2 w-full md:w-80 rounded-xl border-gray-500"
                            type="file"
                            id="image"
                            name="image"
                            accept="image/*"
                            onChange={handleImageChange}
                        />
                    </div>
                    <div>
                        <label className='text-lg font-semibold' htmlFor="brandId">Brand</label><br />
                        <select
                            className='p-2 w-full md:w-80 rounded-xl border-gray-500'
                            id="brandId"
                            name="brandId"
                            value={formData.brandId}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Brand</option>
                            {brands.map(brand => (
                                <option key={brand.id} value={brand.id}>{brand.brandName}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className='text-lg font-semibold' htmlFor="description">Description</label><br />
                        <input
                            className='p-2 w-full md:w-80 rounded-xl border-gray-500'
                            placeholder="Enter the Description"
                            type="text"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label className='text-lg font-semibold' htmlFor="productsCode">Product Code</label><br />
                        <input
                            className='p-2 w-full md:w-80 rounded-xl border-gray-500'
                            placeholder="Enter the Product Code"
                            type="text"
                            id="productsCode"
                            name="productsCode"
                            value={formData.productsCode}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className='justify-center mt-12'>
                    <button onClick={handleCreateProductAndNotify} disabled={isLoading} className="bg-blue-500 text-white px-7 py-2 rounded-lg mt-10 hover:bg-green-600 md:lg:ml-96 mr-16">
                        {isLoading ? 'Saving...' : 'Save'}
                    </button>
                    <button onClick={handleCancel} className="bg-gray-300 text-gray-700 px-7 py-2 rounded-lg mt-10 hover:bg-red-500 md:lg:mr-96">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateProducts;
