import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer here
import rightside from "../Assets/login/rightside.png";
import emailbox from "../Assets/login/emaillogo.png";
import passlock from "../Assets/login/passlock.png";
import f2bimage from "../Assets/login/f2bimage.png";
import Loginsuccess from "../Assets/login/Loginsuccess.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(
      emailRegex.test(value) ? "" : "Please enter a valid email address"
    );
  };

  const validatePassword = (value) => {
    setPasswordError(
      value.length >= 8 ? "" : "Password must be at least 8 characters long"
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (emailError || passwordError) {
      return;
    }

    try {
      const response = await fetch("https://pos.farm2bag.com/api/v1/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const { token, role, user, modelNames } = data;

        localStorage.setItem("token", token);
        localStorage.setItem("role", role);
        localStorage.setItem("userName", user.userName);
        localStorage.setItem("modelNames",modelNames);


        setShowSuccessMessage(true);

        setTimeout(() => {
          navigate("/Dashboard");
        }, 2000);
      } else {
        const errorMessage = await response.text(); // Fetch the error message
        toast.error(`Login failed!`);
      }
    } catch (error) {
      console.error(`Error occurred while logging in: ${error.message || error}`);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ""; // For some browsers
      return ""; // For others
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 h-screen xl:pt-28 xl:pr-32 xl:pl-32 xl:pb-28 max-sm:pt-0 max-sm:pl-0 max-sm:pr-0 max-sm:pb-0">
      <div className="flex justify-center items-center xl:ml-44 bg-white xl:h-3/4 xl:w-3/4 shadow-2xl max-sm:ml-0 sm:w-full relative">
        <img
          src={f2bimage}
          alt="Password"
          className="absolute top-8 left-8 h-12 w-10"
        />
        <form onSubmit={handleSubmit} className="max-w-sm w-full">
          <h2 className="text-start mb-3 text-lg font-semibold">LOGIN</h2>
          <p className="text-start mb-3 text-slate-600">
            Login in to continue to our application
          </p>
          <div className="relative mb-5">
            <input
              type="email"
              placeholder="your@gmail.com"
              value={email}
              onChange={(e) => {
                const lowercasedValue = e.target.value.toLowerCase();
                setEmail(lowercasedValue);
                validateEmail(lowercasedValue);
              }}
              required
              className="w-full px-4 py-2 mt-0 mb-2 border-0 border-b-2 border-sky-500"
            />
            <img
              src={emailbox}
              alt="Email"
              className="absolute right-10 top-3 h-4 w-6"
            />
            {emailError && (
              <p className="text-red-500 text-xs mt-1">{emailError}</p>
            )}
          </div>
          <div className="relative mb-10">
            <input
              type="password"
              placeholder="******"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value);
              }}
              required
              className="w-full px-4 py-2 mb-2 border-0 border-b-2 border-sky-500"
            />
            <img
              src={passlock}
              alt="Password"
              className="absolute right-11 top-3 h-4 w-4"
            />
            {passwordError && (
              <p className="text-red-500 text-xs mt-1">{passwordError}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-72 px-4 py-2 rounded bg-yellow-400 text-white font-semibold hover:bg-green-600 focus:outline-none focus:bg-green-600"
          >
            Login
          </button>
        </form>
      </div>
      <div className="flex justify-center items-center md:h-300 md:w-300 md:w-fit h-3/4 p-0">
        <img src={rightside} alt="Login" className="h-full w-full " />
      </div>

      {showSuccessMessage && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-gray-800 bg-opacity-75 ">
          <div className="text-green-500 text-lg font-semibold flex items-center justify-center bg-white w-1/5 h-1/5">
            <img src={Loginsuccess} className="w-16 h-14" />
            Login Successful!
          </div>
        </div>
      )}
      
      <ToastContainer />
    </div>
  );
}

export default Login;
